import React from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from './AuthContext'

type Props = {
  children?: React.ReactNode
  allowAuthenticated?: boolean
}

export const PublicRoute: React.FC<Props> = ({
  children,
  allowAuthenticated = false,
}) => {
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isAuthenticated && !allowAuthenticated) {
    return <Navigate to="/" />
  }

  return children
}

export default PublicRoute
