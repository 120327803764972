interface AuthFormProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void> | void
  children: React.ReactNode
}
export default function AuthForm(props: AuthFormProps) {
  return (
    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" onSubmit={props.onSubmit}>
        {props.children}
      </form>
    </div>
  )
}
