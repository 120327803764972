import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

import { AssetSetType } from '@droidmap/asset-service-contract'
import {
  CreatePhotogrammetryTaskInput,
  TaskType,
} from '@droidmap/project-service-contract'
import { OdmSettings } from '@droidmap/shared/odm-settings'

import { Button } from '../../components/Button'
import DataTable from '../../components/DataTable'
import Flow from '../../components/Flow'
import LogViewer from '../../components/LogViewer'
import Modal from '../../components/Modal'
import ResourceUsageGraph from '../../components/ResourceUsageGraph'
import Select from '../../components/Select'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { useFormData } from '../../hooks/useFormData'
import {
  fetchAssetSets,
  fetchProjects,
  fetchTasks,
} from '../../lib/clientHelpers'
import { columns } from './columns'
import { cpuData, memoryUsageData, steps, taskLogs } from './dummyData'

export default function Tasks() {
  const [createState, handleCreateChange, resetCreateForm] = useFormData({
    projectId: '',
    assetSetId: '',
  })
  const [createItemOpen, setCreateItemOpen] = useState(false)

  const handleRefresh = () => {
    // Implement your refresh logic here
    console.log('Refreshing logs...')
  }

  const auth = useAuth()
  const queryClient = useQueryClient()
  const { projectClient, assetClient } = useClient()

  const { data: projects = [] } = useQuery({
    queryKey: ['projects', auth.activeOrganisation.id],
    queryFn: () => fetchProjects(auth.activeOrganisation.id, projectClient),
  })

  const { data: tasks = [] } = useQuery({
    queryKey: ['tasks', projects],
    queryFn: () => fetchTasks(projects, projectClient),
    enabled: projects.length > 0,
  })

  const { data: assetSets = [] } = useQuery({
    queryKey: ['assetSets', projects],
    queryFn: () => fetchAssetSets(projects, assetClient),
    enabled: projects.length > 0,
  })

  const createMutation = useMutation({
    mutationFn: async (event: React.FormEvent) => {
      event.preventDefault()
      setCreateItemOpen(false)
      const outputImagesAssetSet = await assetClient.createAssetSet({
        projectId: createState.projectId,
        name: 'Brighton Beach Output',
        assetSetType: AssetSetType.OdmOutputs,
      })

      const odmSettings: OdmSettings = {
        outputs: {
          fastOrthophoto: true,
        },
      }

      const photogrammetryTaskInput: CreatePhotogrammetryTaskInput = {
        projectId: createState.projectId,
        name: 'Brighton Beach',
        type: TaskType.Photogrammetry,
        inputAssetSets: [createState.assetSetId],
        outputAssetSets: [outputImagesAssetSet.id],
        odmSettings: odmSettings,
      }

      console.log(
        `Creating photogrammetry task: ${JSON.stringify(photogrammetryTaskInput)}`,
      )

      return await projectClient.createTask(photogrammetryTaskInput)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['tasks', projects],
      })
      resetCreateForm()
    },
  })

  const handleCreateItem = async () => {
    setCreateItemOpen(true)
  }

  return (
    <div className="flex flex-col space-y-2">
      <Modal
        title="Create Task"
        icon={<PlusCircleIcon />}
        isOpen={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false)
          resetCreateForm()
        }}
      >
        <form onSubmit={createMutation.mutate} className="space-y-6">
          <Select
            label="Select Project"
            id="projectId"
            name="projectId"
            value={createState.projectId}
            onChange={handleCreateChange}
          >
            <option value="">Select a project</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </Select>
          <Select
            label="Select Asset Set"
            id="assetSetId"
            name="assetSetId"
            value={createState.assetSetId}
            onChange={handleCreateChange}
          >
            <option value="">Select an asset set</option>
            {assetSets.map((assetSet) => (
              <option key={assetSet.id} value={assetSet.id}>
                {assetSet.name}
              </option>
            ))}
          </Select>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              type="submit"
              disabled={!createState.projectId || !createState.assetSetId}
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>

      <Flow steps={steps} />

      <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
        <ResourceUsageGraph
          title="CPU Usage"
          data={cpuData}
          color="blue"
          unit="%"
          maxValue={100}
        />
        <ResourceUsageGraph
          title="Memory Usage"
          data={memoryUsageData}
          color="red"
          unit="MB"
          maxValue={8000}
        />
        <ResourceUsageGraph
          title="CPU Usage"
          data={cpuData}
          color="blue"
          unit="%"
          maxValue={100}
        />
        <ResourceUsageGraph
          title="Memory Usage"
          data={memoryUsageData}
          color="red"
          unit="MB"
          maxValue={8000}
        />
      </div>

      <LogViewer tasks={taskLogs} onRefresh={handleRefresh} />

      <DataTable
        data={tasks}
        columns={columns}
        title="Tasks"
        description="Tasks in project."
        onCreate={handleCreateItem}
      />
    </div>
  )
}
