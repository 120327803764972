import { Switch } from '@headlessui/react'
import { SunIcon } from '@heroicons/react/24/outline'
import { MoonIcon } from '@heroicons/react/24/solid'
import { FC, useContext } from 'react'

import { ThemeContext } from '../../context/ThemeContext'
import { cn } from '../../lib/utils'

const ThemeSwitcher: FC = () => {
  const { theme, toggleTheme } = useContext(ThemeContext)

  return (
    <Switch
      checked={theme === 'dark'}
      onChange={toggleTheme}
      className={cn(
        theme === 'dark' ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out',
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={cn(
          theme === 'dark' ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      >
        <span
          className={cn(
            theme === 'dark'
              ? 'opacity-0 duration-100 ease-out'
              : 'opacity-100 duration-200 ease-in',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          )}
          aria-hidden="true"
        >
          <SunIcon className="text-element-1 h-4 w-4" />
        </span>
        <span
          className={cn(
            theme === 'dark'
              ? 'opacity-100 duration-200 ease-in'
              : 'opacity-0 duration-100 ease-out',
            'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity',
          )}
          aria-hidden="true"
        >
          <MoonIcon className="text-surface-1 h-4 w-4" />
        </span>
      </span>
    </Switch>
  )
}

export default ThemeSwitcher
