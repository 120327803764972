import dagre from '@dagrejs/dagre'
import React, { useMemo } from 'react'
import ReactFlow, {
  Edge,
  Node,
  Position,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow'
import 'reactflow/dist/style.css'

import { Card, CardContent } from '../Card'
import { Status } from '../Status'
import {
  DroidMapStatusNode,
  DroidMapStatusNodeData,
  DroidMapStatusNodeType,
} from './DroidMapStatusNode'

export type Step = {
  id: string
  name: string
  status: Status
  startTime: number
  endTime: number
  dependencies: string[]
}

const nodeTypes = { droidmapStatus: DroidMapStatusNode }

/**
 * Arranges the nodes and edges of a graph using the Dagre layout algorithm.
 *
 * @param nodes - An array of nodes to be laid out.
 * @param edges - An array of edges connecting the nodes.
 * @param direction - The direction of the layout, either 'LR' (left-to-right) or 'TB' (top-to-bottom). Defaults to 'LR'.
 * @returns An object containing the updated nodes and edges with their positions set.
 */
const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction: 'LR' | 'TB' = 'LR',
): { nodes: Node[]; edges: Edge[] } => {
  const dagreGraph = new dagre.graphlib.Graph()
  dagreGraph.setDefaultEdgeLabel(() => ({}))

  const nodeWidth = 220
  const nodeHeight = 36

  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  // group nodes by rank
  const groupedNodes: Record<number, Node<DroidMapStatusNodeData>[]> = {}
  nodes.forEach((node) => {
    const nodeData = dagreGraph.node(node.id)
    const rank = nodeData.rank
    if (rank !== undefined) {
      groupedNodes[rank] = groupedNodes[rank] || []
      groupedNodes[rank].push(node)
    }
  })

  // sort the grouped nodes by y-coordinate
  Object.values(groupedNodes).forEach((rankNodes) => {
    rankNodes.sort((a, b) => dagreGraph.node(a.id).y - dagreGraph.node(b.id).y)
  })

  const updatedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    const rank = nodeWithPosition.rank
    const yRank =
      rank !== undefined
        ? groupedNodes[rank].findIndex((n) => n.id === node.id)
        : 0

    return {
      ...node,
      targetPosition: isHorizontal ? Position.Left : Position.Top,
      sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: yRank * 75,
      },
    }
  })

  return { nodes: updatedNodes, edges }
}

const getNodesAndEdges = (
  steps: Step[],
): { nodes: Node<DroidMapStatusNodeData>[]; edges: Edge[] } => {
  const nodes: Node<DroidMapStatusNodeData>[] = steps.map((step) => ({
    id: step.id,
    type: 'droidmapStatus',
    // Initial position is set to (0, 0) and will be updated by the layout algorithm
    position: { x: 0, y: 0 },
    data: {
      label: step.name,
      status: step.status,
      type:
        step.dependencies.length === 0
          ? DroidMapStatusNodeType.Input
          : DroidMapStatusNodeType.Default,
      startTime: step.startTime,
      endTime: step.endTime,
    },
  }))

  const edges: Edge[] = steps.flatMap((step) =>
    step.dependencies.map((dependency) => ({
      id: `${dependency}-${step.id}`,
      source: dependency,
      target: step.id,
    })),
  )

  // Set nodes to output if they don't have any outgoing edges
  nodes.forEach((node) => {
    if (!edges.some((edge) => edge.source === node.id)) {
      node.data.type = DroidMapStatusNodeType.Output
    }
  })

  return getLayoutedElements(nodes, edges)
}

interface FlowProps {
  steps: Step[]
}

const LayoutFlow: React.FC<FlowProps> = ({ steps }) => {
  const { nodes, edges } = useMemo(() => getNodesAndEdges(steps), [steps])
  const { fitView } = useReactFlow()

  React.useEffect(() => {
    // Fit the view to the graph after the nodes have been updated
    // Needs the timeout delay to update the view correctly
    // https://github.com/xyflow/xyflow/issues/533
    setTimeout(() => {
      fitView({ padding: 0.01, includeHiddenNodes: true })
    })
  }, [fitView, nodes])

  return (
    <Card>
      <CardContent>
        <div className="w-full h-[300px]">
          {nodes && (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              nodeTypes={nodeTypes}
              fitView
              panOnScroll={false}
              zoomOnScroll={false}
              panOnDrag={false}
              nodesDraggable={false}
              nodesConnectable={false}
              elementsSelectable={false}
              proOptions={{ hideAttribution: true }}
            />
          )}
        </div>
      </CardContent>
    </Card>
  )
}

const Flow: React.FC<FlowProps> = ({ steps }) => {
  return (
    <ReactFlowProvider>
      <LayoutFlow steps={steps} />
    </ReactFlowProvider>
  )
}

export default Flow
