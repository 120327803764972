import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form'

import {
  CameraLens,
  FeatureQuality,
  FeatureTypeAlgorithm,
  MatcherTypeAlgorithm,
  Merge,
  OdmStage,
  OrthophotoCompression,
  PointCloudQuality,
  RadiometricCalibration,
  SfmAlgorithm,
} from '@droidmap/shared/odm-settings'

import { AccordionItem } from '../Accordion'
import {
  CheckboxController,
  NumberController,
  SelectController,
  TextController,
} from '../FormController'

interface FormSectionsProps<T extends FieldValues> {
  control: Control<T>
  errors: FieldErrors<T>
}

export const FormSections = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="ODM Settings"
    className="text-lg font-semibold text-element-1"
  >
    <div className="ml-4">
      <RuntimeScopeSection<T> control={control} errors={errors} />
      <StageOptionsSection<T> control={control} errors={errors} />
      <SplitMergeOptionsSection<T> control={control} errors={errors} />
      <OutputsSection<T> control={control} errors={errors} />
    </div>
  </AccordionItem>
)

const RuntimeScopeSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="Runtime Scope"
    className="text-md font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <SelectController
        name={'runtimeScope.endWith' as Path<T>}
        control={control}
        defaultValue={OdmStage.STEP12_POST_PROCESS as PathValue<T, Path<T>>}
        label="End With"
        options={Object.values(OdmStage)}
        errors={errors}
      />
      <SelectController
        name={'runtimeScope.rerun' as Path<T>}
        control={control}
        label="Rerun"
        options={['', ...Object.values(OdmStage)]}
        errors={errors}
      />
      <CheckboxController
        name={'runtimeScope.rerunAll' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Rerun All"
        errors={errors}
      />
      <SelectController
        name={'runtimeScope.rerunFrom' as Path<T>}
        control={control}
        label="Rerun From"
        options={['', ...Object.values(OdmStage)]}
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const StageOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="Stage Options"
    className="text-md font-semibold text-element-1"
  >
    <div className="ml-4">
      <GeneralOptionsSection<T> control={control} errors={errors} />
      <OpenSfmOptionsSection<T> control={control} errors={errors} />
      <OpenMvsOptionsSection<T> control={control} errors={errors} />
      <OdmFilterPointsOptionsSection<T> control={control} errors={errors} />
      <OdmMeshingOptionsSection<T> control={control} errors={errors} />
      <MvsTexturingOptionsSection<T> control={control} errors={errors} />
      <OdmDemOptionsSection<T> control={control} errors={errors} />
      <OdmOrthophotoOptionsSection<T> control={control} errors={errors} />
    </div>
  </AccordionItem>
)

const GeneralOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="General Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <CheckboxController
        name={'stageOptions.general.help' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Help"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.general.maxConcurrency' as Path<T>}
        control={control}
        defaultValue={20 as PathValue<T, Path<T>>}
        label="Max Concurrency"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.skyRemoval' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Sky Removal"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.bgRemoval' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Background Removal"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.use3dmesh' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Use 3D Mesh"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.ignoreGsd' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Ignore GSD"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.noGpu' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="No GPU"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.general.crop' as Path<T>}
        control={control}
        defaultValue={3 as PathValue<T, Path<T>>}
        label="Crop"
        errors={errors}
      />
      <TextController
        name={'stageOptions.general.boundary' as Path<T>}
        control={control}
        label="Boundary"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.autoBoundary' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Auto Boundary"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.general.autoBoundaryDistance' as Path<T>}
        control={control}
        defaultValue={0 as PathValue<T, Path<T>>}
        label="Auto Boundary Distance"
        errors={errors}
      />
      <SelectController
        name={'stageOptions.general.pcQuality' as Path<T>}
        control={control}
        defaultValue={PointCloudQuality.Medium as PathValue<T, Path<T>>}
        label="Point Cloud Quality"
        options={Object.values(PointCloudQuality)}
        errors={errors}
      />
      <TextController
        name={'stageOptions.general.gcp' as Path<T>}
        control={control}
        label="GCP"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
      <TextController
        name={'stageOptions.general.geo' as Path<T>}
        control={control}
        label="Geo"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.useExif' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Use Exif"
        errors={errors}
      />
      <TextController
        name={'stageOptions.general.copyToPath' as Path<T>}
        control={control}
        label="Copy to Path"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.optimizeDiskSpace' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Optimize Disk Space"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.general.version' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Version"
        errors={errors}
      />
      <TextController
        name={'stageOptions.general.projectPath' as Path<T>}
        control={control}
        label="Project Path"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const OpenSfmOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="OpenSFM Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <NumberController
        name={'stageOptions.opensfm.minNumFeatures' as Path<T>}
        control={control}
        defaultValue={10000 as PathValue<T, Path<T>>}
        label="Min Num Features"
        errors={errors}
      />
      <SelectController
        name={'stageOptions.opensfm.featureType' as Path<T>}
        control={control}
        defaultValue={FeatureTypeAlgorithm.SIFT as PathValue<T, Path<T>>}
        label="Feature Type"
        options={Object.values(FeatureTypeAlgorithm)}
        errors={errors}
      />
      <SelectController
        name={'stageOptions.opensfm.featureQuality' as Path<T>}
        control={control}
        defaultValue={FeatureQuality.HIGH as PathValue<T, Path<T>>}
        label="Feature Quality"
        options={Object.values(FeatureQuality)}
        errors={errors}
      />
      <SelectController
        name={'stageOptions.opensfm.matcherType' as Path<T>}
        control={control}
        defaultValue={MatcherTypeAlgorithm.FLANN as PathValue<T, Path<T>>}
        label="Matcher Type"
        options={Object.values(MatcherTypeAlgorithm)}
        errors={errors}
      />
      <NumberController
        name={'stageOptions.opensfm.matcherNeighbors' as Path<T>}
        control={control}
        defaultValue={0 as PathValue<T, Path<T>>}
        label="Matcher Neighbors"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.opensfm.useFixedCameraParams' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Use Fixed Camera Params"
        errors={errors}
      />
      <TextController
        name={'stageOptions.opensfm.cameras' as Path<T>}
        control={control}
        label="Cameras"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
      <SelectController
        name={'stageOptions.opensfm.cameraLens' as Path<T>}
        control={control}
        defaultValue={CameraLens.AUTO as PathValue<T, Path<T>>}
        label="Camera Lens"
        options={Object.values(CameraLens)}
        errors={errors}
      />
      <SelectController
        name={'stageOptions.opensfm.radiometricCalibration' as Path<T>}
        control={control}
        defaultValue={RadiometricCalibration.None as PathValue<T, Path<T>>}
        label="Radiometric Calibration"
        options={Object.values(RadiometricCalibration)}
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.opensfm.useHybridBundleAdjustment' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>> as PathValue<T, Path<T>>}
        label="Use Hybrid Bundle Adjustment"
        errors={errors}
      />
      <SelectController
        name={'stageOptions.opensfm.sfmAlgorithm' as Path<T>}
        control={control}
        defaultValue={SfmAlgorithm.Incremental as PathValue<T, Path<T>>}
        label="SfM Algorithm"
        options={Object.values(SfmAlgorithm)}
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.opensfm.rollingShutter' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Rolling Shutter"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.opensfm.rollingShutterReadout' as Path<T>}
        control={control}
        defaultValue={0 as PathValue<T, Path<T>>}
        label="Rolling Shutter Readout"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.opensfm.forceGps' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Force GPS"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.opensfm.gpsAccuracy' as Path<T>}
        control={control}
        defaultValue={10 as PathValue<T, Path<T>>}
        label="GPS Accuracy"
        errors={errors}
      />
      <TextController
        name={'stageOptions.opensfm.primaryBand' as Path<T>}
        control={control}
        label="Primary Band"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.opensfm.skipBandAlignment' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Skip Band Alignment"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const OpenMvsOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="OpenMVS Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <CheckboxController
        name={'stageOptions.openmvs.pcTile' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="PC Tile"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.openmvs.pcSkipGeometric' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="PC Skip Geometric"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const OdmFilterPointsOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="ODM Filter Points Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <NumberController
        name={'stageOptions.odm_filterpoints.pcFilter' as Path<T>}
        control={control}
        defaultValue={2.5 as PathValue<T, Path<T>>}
        label="PC Filter"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.odm_filterpoints.pcSample' as Path<T>}
        control={control}
        defaultValue={0 as PathValue<T, Path<T>>}
        label="PC Sample"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const OdmMeshingOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="ODM Meshing Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <NumberController
        name={'stageOptions.odm_meshing.meshSize' as Path<T>}
        control={control}
        defaultValue={200000 as PathValue<T, Path<T>>}
        label="Mesh Size"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.odm_meshing.meshOctreeDepth' as Path<T>}
        control={control}
        defaultValue={3 as PathValue<T, Path<T>>}
        label="Mesh Octree Depth"
        min="1"
        max="14"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const MvsTexturingOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="MVS Texturing Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <CheckboxController
        name={
          'stageOptions.mvs_texturing.texturingSkipGlobalSeamLeveling' as Path<T>
        }
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Texturing Skip Global Seam Leveling"
        errors={errors}
      />
      <CheckboxController
        name={
          'stageOptions.mvs_texturing.texturingSkipLocalSeamLeveling' as Path<T>
        }
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Texturing Skip Local Seam Leveling"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.mvs_texturing.texturingKeepUnseenFaces' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Texturing Keep Unseen Faces"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.mvs_texturing.texturingSingleMaterial' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Texturing Single Material"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const OdmDemOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="ODM DEM Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <CheckboxController
        name={'stageOptions.odm_dem.pcClassify' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="PC Classify"
      />
      <NumberController
        name={'stageOptions.odm_dem.smrfScalar' as Path<T>}
        control={control}
        defaultValue={1.25 as PathValue<T, Path<T>>}
        label="SMRF Scalar"
        step="0.01"
      />
      <NumberController
        name={'stageOptions.odm_dem.smrfSlope' as Path<T>}
        control={control}
        defaultValue={0.15 as PathValue<T, Path<T>>}
        label="SMRF Slope"
        step="0.01"
      />
      <NumberController
        name={'stageOptions.odm_dem.smrfThreshold' as Path<T>}
        control={control}
        defaultValue={0.5 as PathValue<T, Path<T>>}
        label="SMRF Threshold"
        step="0.01"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.odm_dem.smrfWindow' as Path<T>}
        control={control}
        defaultValue={18.0 as PathValue<T, Path<T>>}
        label="SMRF Window"
        step="0.1"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.odm_dem.demGapfillSteps' as Path<T>}
        control={control}
        defaultValue={3 as PathValue<T, Path<T>>}
        label="DEM Gapfill Steps"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.odm_dem.demResolution' as Path<T>}
        control={control}
        defaultValue={5 as PathValue<T, Path<T>>}
        label="DEM Resolution"
        errors={errors}
      />
      <NumberController
        name={'stageOptions.odm_dem.demDecimation' as Path<T>}
        control={control}
        defaultValue={1 as PathValue<T, Path<T>>}
        label="DEM Decimation"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.odm_dem.demEuclideanMap' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="DEM Euclidean Map"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.odm_dem.pcRectify' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="PC Rectify"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const OdmOrthophotoOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="ODM Orthophoto Options"
    className="text-sm font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <NumberController
        name={'stageOptions.odm_orthophoto.orthophotoResolution' as Path<T>}
        control={control}
        defaultValue={5 as PathValue<T, Path<T>>}
        label="Orthophoto Resolution"
        errors={errors}
      />
      <CheckboxController
        name={'stageOptions.odm_orthophoto.orthophotoCutline' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Orthophoto Cutline"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const SplitMergeOptionsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="Split Merge Options"
    className="text-md font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <SelectController
        name={'splitMerge.merge' as Path<T>}
        control={control}
        defaultValue={Merge.ALL as PathValue<T, Path<T>>}
        label="Merge"
        options={Object.values(Merge)}
        errors={errors}
      />
      <CheckboxController
        name={'splitMerge.smNoAlignment' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Skip Alignment"
        errors={errors}
      />
      <NumberController
        name={'splitMerge.split' as Path<T>}
        control={control}
        defaultValue={999999 as PathValue<T, Path<T>>}
        label="Split"
        errors={errors}
      />
      <NumberController
        name={'splitMerge.splitOverlap' as Path<T>}
        control={control}
        defaultValue={150 as PathValue<T, Path<T>>}
        label="Split Overlap"
        errors={errors}
      />
      <TextController
        name={'splitMerge.splitImageGroups' as Path<T>}
        control={control}
        label="Split Image Groups"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
      <TextController
        name={'splitMerge.clusterODMUrl' as Path<T>}
        control={control}
        label="Cluster ODM URL"
        defaultValue={'' as PathValue<T, Path<T>>}
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

const OutputsSection = <T extends FieldValues>({
  control,
  errors,
}: FormSectionsProps<T>) => (
  <AccordionItem
    title="Outputs"
    className="text-md font-semibold text-element-1"
  >
    <fieldset className="space-y-4 ml-4">
      <CheckboxController
        name={'outputs.fastOrthophoto' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Fast Orthophoto"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.skip3dmodel' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Skip 3D Model"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.skipOrthophoto' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Skip Orthophoto"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.skipReport' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Skip Report"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.pcCsv' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Export Point Cloud (CSV)"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.pcLas' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Export Point Cloud (LAS)"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.pcEpt' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Export Point Cloud (EPT)"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.pcCopc' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Export Point Cloud (COPC)"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.dtm' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Build DTM"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.dsm' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Build DSM"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.orthophotoNoTiled' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Orthophoto No Tiled"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.orthophotoPng' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Generate Orthophoto PNG"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.orthophotoKmz' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Generate Orthophoto KMZ"
        errors={errors}
      />
      <SelectController
        name={'outputs.orthophotoCompression' as Path<T>}
        control={control}
        defaultValue={OrthophotoCompression.DEFLATE as PathValue<T, Path<T>>}
        label="Orthophoto Compression"
        options={Object.values(OrthophotoCompression)}
        errors={errors}
      />
      <CheckboxController
        name={'outputs.tiles' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Generate Tiles"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.threeDTiles' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Generate 3D Tiles"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.cog' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Create Cloud-Optimized GeoTIFFs"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.buildOverviews' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Build Orthophoto Overviews"
        errors={errors}
      />
      <CheckboxController
        name={'outputs.gltf' as Path<T>}
        control={control}
        defaultValue={false as PathValue<T, Path<T>>}
        label="Generate GLTF"
        errors={errors}
      />
    </fieldset>
  </AccordionItem>
)

export default FormSections
