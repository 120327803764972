import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { CreatePhotogrammetryTaskInput } from '@droidmap/project-service-contract'
import { OdmSettings } from '@droidmap/shared/odm-settings'

import CardGrid from '../../components/CardGrid'
import DataTable from '../../components/DataTable'
import Modal from '../../components/Modal'
import OdmForm from '../../components/OdmForm'
import PageHeader from '../../components/PageHeader'
import { useClient } from '../../context/ClientContext'
import { fetchAssetSets, fetchTasks } from '../../lib/clientHelpers'
import { TaskFormData, taskSchema } from '../../lib/taskFormSchema'
import { formatBytes, getDirtyValues } from '../../lib/utils'
import { columns } from './columns'

export default function Project() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { projectClient, assetClient } = useClient()
  const [createItemOpen, setCreateItemOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewItemOpen, setViewItemOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTaskId, setSelectedTaskId] = useState<string>('')

  const { id: projectId } = useParams<{ id: string }>()

  if (!projectId) {
    throw new Error('No project ID provided')
  }

  const form = useForm<TaskFormData>({
    resolver: zodResolver(taskSchema),
    mode: 'all',
  })

  const {
    data: project,
    isError: isProjectError,
    error: projectError,
    isLoading: isProjectLoading,
  } = useQuery({
    queryKey: ['project', projectId],
    queryFn: () => projectClient.getProject({ projectId }),
  })

  const assetSetsQuery = useQuery({
    queryKey: ['assetSets', projectId],
    queryFn: () => {
      if (!project) {
        return Promise.reject('Project data is not available yet.')
      }
      return fetchAssetSets([project], assetClient)
    },
    enabled: !!project,
  })

  const tasksQuery = useQuery({
    queryKey: ['tasks', projectId],
    queryFn: () => {
      if (!project) {
        return Promise.reject('Project data is not available yet.')
      }
      return fetchTasks([project], projectClient)
    },
    enabled: !!project,
  })

  const createMutation = useMutation({
    mutationFn: async (formData: TaskFormData) => {
      setCreateItemOpen(false)

      if (!project?.id) {
        throw new Error('Project ID is not defined')
      }

      if (!project?.orgId) {
        throw new Error('Org ID is not defined')
      }

      const validSchema = taskSchema.safeParse(formData)

      if (!validSchema.success) {
        throw new Error(validSchema.error.errors.join(', '))
      }

      const outputImagesAssetSet = await assetClient.createAssetSet({
        projectId: project.id,
        name: `${formData.droidmap.taskName} - ${formData.droidmap.outputAssetSetType}`,
        assetSetType: formData.droidmap.outputAssetSetType,
      })

      const dirtyValues = getDirtyValues(
        { ...form.formState.dirtyFields, droidmap: undefined },
        formData,
      ) as OdmSettings

      const photogrammetryTaskInput: CreatePhotogrammetryTaskInput = {
        projectId: project.id,
        name: formData.droidmap.taskName,
        type: formData.droidmap.taskType,
        inputAssetSets: [formData.droidmap.assetSetId],
        outputAssetSets: [outputImagesAssetSet.id],
        odmSettings: dirtyValues,
      }

      return await projectClient.createTask(photogrammetryTaskInput)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['tasks', project],
      })
      form.reset()
    },
  })

  const onSubmit = (data: TaskFormData) => {
    createMutation.mutate(data)
  }

  if (isProjectLoading) {
    return <div>Loading...</div>
  }

  if (isProjectError) {
    return <div>Error: {projectError?.message}</div>
  }

  const handleCreateItem = () => {
    setCreateItemOpen(true)
  }

  const handleCreateAssetSet = () => {
    navigate('/asset-sets/create')
  }

  const handleViewTask = (taskId: string) => {
    setViewItemOpen(true)
    setSelectedTaskId(taskId)
  }

  return (
    <div>
      <PageHeader title={`Project: ${project?.name} - ${project?.id}`} />
      <div className="mt-6 h-96">
        <CardGrid
          title="Asset Sets"
          description="Asset Sets you own."
          onCreate={handleCreateAssetSet}
          data={
            assetSetsQuery.data?.map((assetSet) => ({
              title: assetSet.name,
              id: assetSet.id,
              details: [
                `Type: ${assetSet.assetSetType}`,
                `Size: ${formatBytes(assetSet.storageSummary.standard)}`,
              ],
              image: 'icon_white_square.png',
              link: `/asset-sets/${assetSet.id}`,
            })) || []
          }
        />
      </div>
      <div className="mt-6 pb-6">
        <DataTable
          data={tasksQuery.data || []}
          columns={columns}
          title="Tasks"
          description="Tasks in project."
          onCreate={handleCreateItem}
          tableOptions={{
            meta: {
              onView: handleViewTask,
            },
          }}
        />
      </div>
      <Modal
        title="Create Task"
        icon={<PlusCircleIcon />}
        isOpen={createItemOpen}
        onClose={() => {
          setCreateItemOpen(false)
          form.reset()
        }}
      >
        <OdmForm<TaskFormData>
          form={form}
          onSubmit={onSubmit}
          assetSets={assetSetsQuery.data || []}
        />
      </Modal>
      {/* {viewItemOpen && (
        <TaskDetailsModal
          taskId={selectedTaskId}
          onClose={() => setViewItemOpen(false)}
        />
      )} */}
    </div>
  )
}
