import React, { useContext } from 'react'

import { ThemeContext } from '../../context/ThemeContext'

// type CheckboxProps = Omit<RadixCheckboxProps, 'checked'> & {
//   checked?: boolean
//   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
//   label?: string
// }

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    useContext(ThemeContext)
    return (
      <div className="mt-2 flex items-center align-center outline-none">
        <input
          type="checkbox"
          {...props}
          ref={ref}
          className={`bg-white text-white w-4 h-4 rounded-md flex items-center align-center justify-center border-0 focus:ring-inset focus:ring-offset-indigo-500 focus:ring-0 shadow-sm`}
        />
        <label
          htmlFor={props.name ?? props.id}
          className="ml-2 block text-sm font-medium text-element-1"
        >
          {props.label}
        </label>
      </div>
    )
  },
)

// const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>(
//   (props, ref) => {
//     const { checked: checkedProp, onChange, ...otherProps } = props
//     const [isChecked, setIsChecked] = useState(checkedProp ?? false)
//     const { theme } = useContext(ThemeContext)

//     useEffect(() => {
//       setIsChecked(checkedProp ?? false)
//     }, [checkedProp])

//     const handleCheckboxChange = (checked: boolean) => {
//       setIsChecked(checked)
//       if (onChange) {
//         onChange({
//           target: { checked },
//         } as unknown as React.ChangeEvent<HTMLInputElement>)
//       }
//     }

//     return (
//       <div>
//         <label
//           htmlFor={props.name ?? props.id}
//           className="block text-sm font-medium leading-6 text-element-1"
//         >
//           {props.label}
//         </label>
//         <Root
//           {...otherProps}
//           checked={isChecked}
//           onCheckedChange={handleCheckboxChange}
//           ref={ref}
//           className="bg-input w-4 h-4 rounded-md flex items-center shadow-md align-center justify-center ring-1 ring-inset ring-white/10"
//         >
//           <Indicator
//             className={`${theme === 'dark' ? 'bg-white' : 'bg-black'} w-4 h-4 flex rounded-md align-center justify-center`}
//           >
//             <CheckIcon
//               className={`w-4 h-3 stroke-2 mt-0.5 ${theme === 'dark' ? 'stroke-black' : 'stroke-white'}`}
//             />
//           </Indicator>
//         </Root>
//       </div>
//     )
//   },
// )

Checkbox.displayName = 'Checkbox'

export default Checkbox
