import { ReactNode, createContext, useContext, useMemo } from 'react'

import { AssetServiceClient } from '@droidmap/asset-service-contract'
import { MappingJobServiceClient } from '@droidmap/mapping-job-service-contract'
import { OrganisationServiceClient } from '@droidmap/organisation-service-contract'
import { ProjectServiceClient } from '@droidmap/project-service-contract'
import {
  ServiceClient,
  ServiceClientContext,
} from '@droidmap/shared/service-contract'
import { TileServiceClient } from '@droidmap/tile-service-contract'
import { UserServiceClient } from '@droidmap/user-service-contract'

import { useAuth } from '../context/AuthContext'
import { useConfig } from './ConfigContext'

const ClientContext = createContext<Client | null>(null)

export const useClient = (): Client => {
  const context = useContext(ClientContext)
  if (!context) {
    throw new Error('useClient must be used within a ClientProvider')
  }
  return context
}

const createServiceEndpoint = (service: string, env: string): string => {
  return `https://${service}.api.${env}.droidmap.com`
}

interface ClientProviderProps {
  children: ReactNode
}
type ServiceClientConstructor<T extends ServiceClient> = new (
  config: ServiceClientContext,
) => T

type Client = {
  organisationClient: OrganisationServiceClient
  projectClient: ProjectServiceClient
  assetClient: AssetServiceClient
  userClient: UserServiceClient
  mappingJobClient: MappingJobServiceClient
  tileClient: TileServiceClient
}

export const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const { ENV } = useConfig()
  const auth = useAuth()

  const createClient = useMemo(
    () =>
      <T extends ServiceClient>(
        ServiceClientConstructor: ServiceClientConstructor<T>,
        serviceName: string,
      ): T => {
        return new ServiceClientConstructor({
          getToken: auth.getAccessToken,
          endpoint: createServiceEndpoint(serviceName, ENV),
          droidMapMetadata: {
            userId: auth.userDetails.id,
            orgId: auth.activeOrganisation.id,
          },
        })
      },
    [ENV, auth],
  )

  const clients = useMemo<Client | null>(() => {
    if (
      !auth.isAuthenticated ||
      !auth.userDetails?.id ||
      !auth.activeOrganisation?.id
    ) {
      return null
    }

    return {
      organisationClient: createClient(
        OrganisationServiceClient,
        'organisation',
      ),
      projectClient: createClient(ProjectServiceClient, 'project'),
      assetClient: createClient(AssetServiceClient, 'asset'),
      userClient: createClient(UserServiceClient, 'user'),
      mappingJobClient: createClient(MappingJobServiceClient, 'mapping-job'),
      tileClient: createClient(TileServiceClient, 'tile'),
    }
  }, [createClient, auth])

  return (
    <ClientContext.Provider value={clients}>{children}</ClientContext.Provider>
  )
}

// Standalone functions for use outside of React components
export const createStandaloneOrganisationClient = (
  getToken: () => Promise<string>,
  userId: string,
  orgId: string,
  env: string,
): OrganisationServiceClient => {
  return new OrganisationServiceClient({
    getToken,
    endpoint: createServiceEndpoint('organisation', env),
    droidMapMetadata: { userId, orgId },
  })
}

export const createStandaloneUserServiceClient = (
  getToken: () => Promise<string>,
  userId: string,
  orgId: string,
  env: string,
): UserServiceClient => {
  return new UserServiceClient({
    getToken,
    endpoint: createServiceEndpoint('user', env),
    droidMapMetadata: { userId, orgId },
  })
}
