import { useQuery } from '@tanstack/react-query'

import Map from '../../components/Map'
import { useAuth } from '../../context/AuthContext'

export default function Maps() {
  const auth = useAuth()

  const authQuery = useQuery({
    queryKey: ['authAccessToken', auth],
    queryFn: () => auth.getAccessToken(),
  })

  if (authQuery.isLoading) {
    return <div>Loading</div>
  }

  if (authQuery.isError) {
    return <div>Error {authQuery.error.message}</div>
  }

  if (!authQuery.data) {
    return <div>No access token available</div>
  }

  return <Map assetId="01JC451TWZ4R065E309H9R14AS" />
}
