import { Navigate, Route, Routes } from 'react-router-dom'

import { PrivateRoute } from '../context/PrivateRoute'
import { PublicRoute } from '../context/PublicRoute'
import pagesData from './pagesData'

const Router = () => {
  const pageRoutes = pagesData.map((page) => {
    if (page.public) {
      return (
        <Route
          key={page.title}
          path={page.path}
          element={<PublicRoute>{page.element}</PublicRoute>}
        />
      )
    }
    return (
      <Route
        key={page.title}
        path={page.path}
        element={<PrivateRoute>{page.element}</PrivateRoute>}
      />
    )
  })
  return (
    <Routes>
      {pageRoutes}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default Router
