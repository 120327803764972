import { z } from 'zod'

export enum OdmStage {
  STEP0_DATASET = 'dataset',
  STEP1_SPLIT = 'split',
  STEP2_MERGE = 'merge',
  /**
   * OpenSfM Structure from Motion library (OpenSfM)
   *
   * Produces a sparse point-cloud from a set of images.
   */
  STEP3_OPEN_SFM = 'opensfm',
  /**
   * Open Multi-View Stereo reconstruction library (OpenMVS)
   *
   * The input is a set of camera poses plus the sparse point-cloud and the
   * output in this step is a dense point-cloud reconstruction for obtaining
   * a complete and as accurate as possible point-cloud. This step is only
   * needed if you want to generate a 3d model.
   */
  STEP4_OPEN_MVS = 'openmvs',
  /**
   * Optional steps to filter parts of the generated point cloud.
   */
  STEP5_FILTER_POINTS = 'odm_filterpoints',
  /**
   * From the filtered dense point-cloud, we generate a mesh
   */
  STEP6_MESHING = 'odm_meshing',
  /**
   * Open Multi-View Stereo reconsutrction library (OpenMVS)
   *
   * In this step we again use OpenMVS to generate accurate textures
   * and colors for the mesh created in the previous step.
   */
  STEP7_TEXTURING = 'mvs_texturing',
  /**
   * Make final adjustments to the georeferencing of the point cloud.
   */
  STEP8_GEOREFERENCING = 'odm_georeferencing',
  /**
   * Build a Digital Elevation Model (DEM) from the point cloud.
   */
  STEP9_DEM = 'odm_dem',
  /**
   * Build an orthophoto from the point cloud.
   */
  STEP10_ORTHOPHOTO = 'odm_orthophoto',
  /**
   * Generate a report of the processing pipeline run .
   */
  STEP11_REPORT = 'odm_report',
  STEP12_POST_PROCESS = 'odm_postprocess',
}

export const OdmStageSchema = z.nativeEnum(OdmStage)

export const getNextStage = (stage: OdmStage | undefined): OdmStage => {
  if (!stage) {
    return OdmStage.STEP0_DATASET
  }

  switch (stage) {
    case OdmStage.STEP0_DATASET:
      return OdmStage.STEP1_SPLIT
    case OdmStage.STEP1_SPLIT:
      return OdmStage.STEP2_MERGE
    case OdmStage.STEP2_MERGE:
      return OdmStage.STEP3_OPEN_SFM
    case OdmStage.STEP3_OPEN_SFM:
      return OdmStage.STEP4_OPEN_MVS
    case OdmStage.STEP4_OPEN_MVS:
      return OdmStage.STEP5_FILTER_POINTS
    case OdmStage.STEP5_FILTER_POINTS:
      return OdmStage.STEP6_MESHING
    case OdmStage.STEP6_MESHING:
      return OdmStage.STEP7_TEXTURING
    case OdmStage.STEP7_TEXTURING:
      return OdmStage.STEP8_GEOREFERENCING
    case OdmStage.STEP8_GEOREFERENCING:
      return OdmStage.STEP9_DEM
    case OdmStage.STEP9_DEM:
      return OdmStage.STEP10_ORTHOPHOTO
    case OdmStage.STEP10_ORTHOPHOTO:
      return OdmStage.STEP11_REPORT
    case OdmStage.STEP11_REPORT:
      return OdmStage.STEP12_POST_PROCESS
    case OdmStage.STEP12_POST_PROCESS:
      throw new Error(`No next stage after ${stage}`)
    default:
      throw new Error(`Unknown stage ${stage}`)
  }
}

export enum FeatureTypeAlgorithm {
  AKAZE = 'akaze',
  HAHOG = 'hahog',
  ORB = 'orb',
  SIFT = 'sift',
}

export const FeatureTypeAlgorithmSchema = z.nativeEnum(FeatureTypeAlgorithm)

export enum FeatureQuality {
  LOWEST = 'lowest',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  ULTRA = 'ultra',
}

export const FeatureQualitySchema = z.nativeEnum(FeatureQuality)

export enum MatcherTypeAlgorithm {
  BRUTE_FORCE = 'bruteforce',
  FLANN = 'flann',
  BOW = 'bow',
}

export const MatcherTypeAlgorithmSchema = z.nativeEnum(MatcherTypeAlgorithm)

export enum CameraLens {
  AUTO = 'auto',
  PERSPECTIVE = 'perspective',
  BROWN = 'brown',
  FISHEYE = 'fisheye',
  SPHERICAL = 'spherical',
  EQUIRECTANGULAR = 'equirectangular',
  DUAL = 'dual',
}

export const CameraLensSchema = z.nativeEnum(CameraLens)

export enum RadiometricCalibration {
  None = 'none',
  Camera = 'camera',
  CameraAndSun = 'camera+sun',
}

export const RadiometricCalibrationSchema = z.nativeEnum(RadiometricCalibration)

export enum SfmAlgorithm {
  Incremental = 'incremental',
  Triangulation = 'triangulation',
  Planar = 'planar',
}

export const SfmAlgorithmSchema = z.nativeEnum(SfmAlgorithm)

export enum PointCloudQuality {
  Ultra = 'ultra',
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Lowest = 'lowest',
}

export const PointCloudQualitySchema = z.nativeEnum(PointCloudQuality)

export enum OrthophotoCompression {
  JPEG = 'JPEG',
  LZW = 'LZW',
  PACKBITS = 'PACKBITS',
  DEFLATE = 'DEFLATE',
  LZMA = 'LZMA',
  NONE = 'NONE',
}

export const OrthophotoCompressionSchema = z.nativeEnum(OrthophotoCompression)

export enum Merge {
  ALL = 'all',
  POINTCLOUD = 'pointcloud',
  ORTHOPHOTO = 'orthophoto',
  DEM = 'dem',
}

export const MergeSchema = z.nativeEnum(Merge)

/**
 * OdmSettings schema
 */
export const OdmSettingsSchema = z.object({
  runtimeScope: z
    .object({
      /**
       * End processing at this stage. Can be any of the OdmStage enum values.
       *
       * @default OdmStage.STEP12_POST_PROCESS
       */
      endWith: OdmStageSchema.optional(),

      /**
       * Rerun this stage only and stop. Can be any of the OdmStage enum values.
       *
       * @default None
       */
      rerun: OdmStageSchema.optional(),

      /**
       * Permanently delete all previous results and rerun the processing pipeline.
       *
       * @default false
       */
      rerunAll: z.boolean().optional(),

      /**
       * Rerun processing from this stage. Can be any of the OdmStage enum values.
       *
       * @default None
       */
      rerunFrom: OdmStageSchema.optional(),
    })
    .optional(),

  stageOptions: z
    .object({
      general: z
        .object({
          /**
           * Show the help message and exit.
           */
          help: z.boolean().optional(),

          /**
           * The maximum number of processes to use in various processes. Peak memory
           * requirement is ~1GB per thread and 2 megapixel image resolution.
           *
           * @default 20
           */
          maxConcurrency: z.coerce
            .number()
            .min(1)
            .max(50)
            .optional()
            .default(20),

          /**
           * Automatically compute image masks using AI to remove the sky.
           * Experimental.
           *
           * @default false
           */
          skyRemoval: z.boolean().optional(),

          /**
           * Automatically compute image masks using AI to remove the background.
           * Experimental.
           *
           * @default false
           */
          bgRemoval: z.boolean().optional(),

          /**
           * Use a full 3D mesh to compute the orthophoto instead of a 2.5D mesh. This
           * option is a bit faster and provides similar results in planar areas.
           *
           * @default false
           */
          use3dmesh: z.boolean().optional(),

          /**
           * Ignore Ground Sampling Distance (GSD). GSD caps the maximum resolution of
           * image outputs and resizes images when necessary, resulting in faster
           * processing and lower memory usage. Since GSD is an estimate, sometimes
           * ignoring it can result in slightly better image output quality.
           *
           * @default false
           */
          ignoreGsd: z.boolean().optional(),

          /**
           * Do not use GPU acceleration, even if it's available.
           *
           * @default false
           */
          noGpu: z.boolean().optional(),

          /**
           * Automatically crop image outputs by creating a smooth buffer around the
           * dataset boundaries, shrunk by N meters. Use 0 to disable cropping.
           *
           * @default 3
           */
          crop: z.number().optional(),

          /**
           * GeoJSON polygon limiting the area of the reconstruction.
           * Can be specified either as path to a GeoJSON file or as a JSON string representing
           * the contents of a GeoJSON file.
           */
          boundary: z.string().optional(),

          /**
           * Automatically set a boundary using camera shot locations to limit the area of the
           * reconstruction. This can help remove far away background artifacts (sky, background
           * landscapes, etc.). See also --boundary.
           *
           * @default false
           */
          autoBoundary: z.boolean().optional(),

          /**
           * Specify the distance between camera shot locations and the outer edge of the boundary
           * when computing the boundary with --auto-boundary. Set to 0 to automatically choose a value.
           *
           * @default 0
           */
          autoBoundaryDistance: z.number().optional(),

          /**
           * Set point cloud quality. Higher quality generates better, denser point clouds, but
           * requires more memory and takes longer. Each step up in quality increases processing
           * time roughly by a factor of 4x. Can be one of: ultra, high, medium, low, lowest.
           *
           * @default PcQuality.Medium
           */
          pcQuality: PointCloudQualitySchema.optional(),

          /**
           * Path to the file containing the ground control points used for georeferencing. The file needs to use the following format:
           * EPSG:<code> or <+proj definition> geo_x geo_y geo_z im_x im_y image_name [gcp_name] [extra1] [extra2]
           */
          gcp: z.string().optional(),

          /**
           * Path to the image geolocation file containing the camera center coordinates used for georeferencing.
           * If you dont have values for omega/phi/kappa you can set them to 0. The file needs to use the following format:
           * EPSG:<code> or <+proj definition> image_name geo_x geo_y geo_z [omega (degrees)] [phi (degrees)] [kappa (degrees)]
           * [horz accuracy (meters)] [vert accuracy (meters)]
           */
          geo: z.string().optional(),

          /**
           * Use this tag if you have a GCP File but want to use the EXIF information for georeferencing instead.
           *
           * @default false
           */
          useExif: z.boolean().optional(),

          /**
           * Copy output results to this folder after processing.
           */
          copyToPath: z.string().optional(),

          /**
           * Delete heavy intermediate files to optimize disk space usage. This affects the ability to
           * restart the pipeline from an intermediate stage, but allows datasets to be processed on
           * machines that don't have sufficient disk space available.
           *
           * @default false
           */
          optimizeDiskSpace: z.boolean().optional(),

          /**
           * Displays version number and exits.
           */
          version: z.boolean().optional(),

          /**
           * Path to the project folder. Your project folder should contain subfolders for
           * each dataset. Each dataset should have an "images" folder.
           */
          projectPath: z.string().optional(),
        })
        .optional(),

      opensfm: z
        .object({
          /**
           * Minimum number of features to extract per image. More features can be
           * useful for finding more matches between images, potentially allowing
           * the reconstruction of areas with little overlap or insufficient features.
           * More features also slows down processing.
           *
           * @default 10000
           */
          minNumFeatures: z.number().optional(),

          /**
           * Choose the algorithm for extracting keypoints and computing descriptors.
           * Can be one of: akaze, hahog, orb, sift.
           *
           * @default: FeatureTypeAlgorithm.SIFT
           */
          featureType: FeatureTypeAlgorithmSchema.optional(),

          /**
           * Set feature extraction quality. Higher quality generates better
           * features, but requires more memory and takes longer. Can be one
           * of: lowest, low, medium, high, ultra.
           *
           * @default FeatureQuality.HIGH
           */
          featureQuality: FeatureQualitySchema.optional(),

          /**
           * Matcher algorithm, Fast Library for Approximate Nearest Neighbors or
           * Bag of Words. FLANN is slower, but more stable. BOW is faster, but
           * can sometimes miss valid matches. BRUTEFORCE is very slow but
           * robust. Can be one of: bow, bruteforce, flann.
           *
           * @default MatcherTypeAlgorithm.FLANN
           */
          matcherType: MatcherTypeAlgorithmSchema.optional(),

          /**
           * Perform image matching with the nearest images based on GPS exif data.
           * A value greater than 0 indicates the number of images to match with
           * based on nearest by GPS data. Set to 0 to match by triangulation.
           *
           * @default 0
           */
          matcherNeighbors: z.number().optional(),

          /**
           * Turn off camera parameter optimization during bundle adjustment.
           * This can be sometimes useful for improving results that exhibit
           * doming/bowling or when images are taken with a rolling shutter
           * camera.
           *
           * @default false
           */
          useFixedCameraParams: z.boolean().optional(),

          /**
           * Use the camera parameters computed from another dataset instead
           * of calculating them. Can be specified either as path to a
           * cameras.json file or as a JSON string representing the contents
           * of a cameras.json file.
           */
          cameras: z.string().optional(),

          /**
           * Set a camera projection type. Manually setting a value can help
           * improve geometric undistortion. By default the application
           * tries to determine a lens type from the images metadata. Can
           * be one of: auto, perspective, brown, fisheye, spherical,
           * equirectangular, dual.
           *
           * @default CAMERA_LENS.AUTO
           */
          cameraLens: CameraLensSchema.optional(),

          /**
           * Set the radiometric calibration to perform on images. When processing
           * multispectral and thermal images you should set this option to obtain
           * reflectance/temperature values (otherwise you will get digital number
           * values).
           *
           * [camera] applies black level, vignetting, row gradient gain/exposure
           * compensation (if appropriate EXIF tags are found) and computes absolute
           * temperature values.
           *
           * [camera+sun] is experimental, applies all the corrections of [camera], plus
           * compensates for spectral radiance registered via a downwelling light sensor
           * (DLS) taking in consideration the angle of the sun.
           *
           * Can be one of: none, camera, camera+sun.
           *
           * @default RadiometricCalibration.None
           */
          radiometricCalibration: RadiometricCalibrationSchema.optional(),

          /**
           * Run local bundle adjustment for every image added to the reconstruction and
           * a global adjustment every 100 images. Speeds up reconstruction for very large
           * datasets.
           *
           * @default false
           */
          useHybridBundleAdjustment: z.boolean().optional(),

          /**
           * Choose the structure from motion algorithm. For aerial datasets, if camera
           * GPS positions and angles are available, triangulation can generate better
           * results. For planar scenes captured at fixed altitude with nadir-only
           * images, planar can be much faster.
           *
           * Can be one of: incremental, triangulation, planar.
           *
           * @default SfmAlgorithm.Incremental
           */
          sfmAlgorithm: SfmAlgorithmSchema.optional(),

          /**
           * Turn on rolling shutter correction. If the camera has a rolling shutter and the
           * images were taken in motion, you can turn on this option to improve the accuracy
           * of the results. See also --rolling-shutter-readout.
           *
           * @default false
           */
          rollingShutter: z.boolean().optional(),

          /**
           * Override the rolling shutter readout time for your camera sensor (in
           * milliseconds), instead of using the rolling shutter readout database. Note that
           * not all cameras are present in the database. Set to 0 to use the database
           * value.
           *
           * @default 0
           */
          rollingShutterReadout: z.number().optional(),

          /**
           * Use images' GPS exif data for reconstruction, even if there are GCPs
           * present.This flag is useful if you have high precision GPS
           * measurements. If there are no GCPs, this flag does nothing.
           *
           * @default false
           */
          forceGps: z.boolean().optional(),

          /**
           * Set a value in meters for the GPS Dilution of Precision (DOP) information for all images. If
           * your images are tagged with high precision GPS information (RTK), this value will be
           * automatically set accordingly. You can use this option to manually set it in case the
           * reconstruction fails. Lowering this option can sometimes help control bowling-effects over
           * large areas.
           *
           * @default 10
           */
          gpsAccuracy: z.number().optional(),

          /**
           * When processing multispectral datasets, you can specify the name of the primary band that
           * will be used for reconstruction. It's recommended to choose a band which has sharp details
           * and is in focus.
           *
           * @default "auto"
           */
          primaryBand: z.string().optional(),

          /**
           * When processing multispectral datasets, ODM will automatically align the images for each
           * band. If the images have been postprocessed and are already aligned, use this option.
           *
           * @default false
           */
          skipBandAlignment: z.boolean().optional(),
        })
        .optional(),

      openmvs: z
        .object({
          /**
           * Reduce the memory usage needed for depthmap fusion by splitting large scenes into tiles.
           * Turn this on if your machine doesn't have much RAM and/or you've set --pc-quality to high
           * or ultra. Experimental.
           *
           * @default false
           */
          pcTile: z.boolean().optional(),

          /**
           * Geometric estimates improve the accuracy of the point cloud by
           * computing geometrically consistent depthmaps but may not be usable in
           * larger datasets. This flag disables geometric estimates.
           */
          pcSkipGeometric: z.boolean().optional(),
        })
        .optional(),

      odm_filterpoints: z
        .object({
          /**
           * Filters the point cloud by removing points that deviate more than N standard deviations
           * from the local mean. Set to 0 to disable filtering.
           *
           * @default 2.5
           */
          pcFilter: z.number().optional(),

          /**
           * Filters the point cloud by keeping only a single point around a radius N (in meters). This
           * can be useful to limit the output resolution of the point cloud and remove duplicate
           * points. Set to 0 to disable sampling.
           *
           * @default 0
           */
          pcSample: z.number().optional(),
        })
        .optional(),

      odm_meshing: z
        .object({
          /**
           * The maximum vertex count of the output mesh.
           *
           * @default 200000
           */
          meshSize: z.number().optional(),

          /**
           * Octree depth used in the mesh reconstruction, increase to get more vertices,
           * recommended values are 8-12.
           *
           * Range is a number between 1 and 14.
           *
           * @default 3
           */
          meshOctreeDepth: z.number().optional(),
        })
        .optional(),

      mvs_texturing: z
        .object({
          /**
           * Skip normalization of colors across all images. Useful when processing radiometric data.
           *
           * @default false
           */
          texturingSkipGlobalSeamLeveling: z.boolean().optional(),

          /**
           * Skip the blending of colors near seams.
           *
           * @default false
           */
          texturingSkipLocalSeamLeveling: z.boolean().optional(),

          /**
           * Keep faces in the mesh that are not seen in any camera.
           *
           * @default false
           */
          texturingKeepUnseenFaces: z.boolean().optional(),

          /**
           * Generate OBJs that have a single material and a single texture file
           * instead of multiple ones.
           */
          texturingSingleMaterial: z.boolean().optional(),
        })
        .optional(),

      odm_dem: z
        .object({
          /**
           * Classify the point cloud outputs using a Simple Morphological Filter. You can control
           * the behavior of this option by tweaking the --dem-* parameters.
           *
           * @default false
           */
          pcClassify: z.boolean().optional(),

          /**
           * Simple Morphological Filter elevation scalar parameter
           *
           * @default 1.25
           */
          smrfScalar: z.number().optional(),

          /**
           * Simple Morphological Filter slope parameter (rise over run)
           *
           * @default 0.15
           */
          smrfSlope: z.number().optional(),

          /**
           * Simple Morphological Filter elevation threshold parameter (meters)
           *
           * @default 0.5
           */
          smrfThreshold: z.number().optional(),

          /**
           * Simple Morphological Filter window radius parameter (meters)
           *
           * @default 18.0
           */
          smrfWindow: z.number().optional(),

          /**
           * Number of steps used to fill areas with gaps. Set to 0 to disable gap filling. Starting with a radius equal to the output
           * resolution, N different DEMs are generated with progressively bigger radius using the inverse distance weighted (IDW)
           * algorithm and merged together. Remaining gaps are then merged using nearest neighbor interpolation.
           *
           * @default 3
           */
          demGapfillSteps: z.number().optional(),

          /**
           * DSM/DTM resolution in cm / pixel. Note that this value is capped to 2x the ground sampling distance (GSD) estimate. To remove
           * the cap, check --ignore-gsd also.
           *
           * @default 5
           */
          demResolution: z.number().optional(),

          /**
           * Decimate the points before generating the DEM. 1 is no decimation (full quality). 100 decimates ~99% of the points. Useful for
           * speeding up generation of DEM results in very large datasets.
           *
           * @default 1
           */
          demDecimation: z.number().optional(),

          /**
           * Computes an euclidean raster map for each DEM. The map reports the distance from each cell to the nearest NODATA value (before
           * any hole filling takes place). This can be useful to isolate the areas that have been filled.
           *
           * @default false
           */
          demEuclideanMap: z.boolean().optional(),

          /**
           * Perform ground rectification on the point cloud. This means that wrongly classified ground
           * points will be re-classified and gaps will be filled. Useful for generating DTMs.
           *
           * @default false
           */
          pcRectify: z.boolean().optional(),
        })
        .optional(),

      odm_orthophoto: z
        .object({
          /**
           * Orthophoto resolution in cm / pixel. Note that this value is capped by a ground sampling distance (GSD) estimate. To remove
           * the cap, check --ignore-gsd also.
           *
           * @default 5
           */
          orthophotoResolution: z.number().optional(),
          /**
           * Generates a polygon around the cropping area that cuts the orthophoto around
           * the edges of features. This polygon can be useful for stitching seamless
           * mosaics with multiple overlapping orthophotos.
           *
           * @default false
           */
          orthophotoCutline: z.boolean().optional(),
        })
        .optional(),
    })
    .optional(),

  outputs: z
    .object({
      /**
       * Skips dense reconstruction and 3D model generation. It generates an
       * orthophoto directly from the sparse reconstruction. If you just need
       * an orthophoto and do not need a full 3D model, turn on this option.
       *
       * Specifically this option skips the openmvs stage and enables the
       * skip-3dmodel flag.
       *
       * @default false
       */
      fastOrthophoto: z.boolean().optional(),

      /**
       * Skip generation of a full 3D model. This can save time if you only need 2D
       * results such as orthophotos and DEMs. If you choose the fast_orthophoto option,
       * this option is automatically set to true.
       *
       * @default false
       */
      skip3dmodel: z.boolean().optional(),

      /**
       * Skip generation of the orthophoto. This can save time if you only need 3D
       * results or DEMs.
       *
       * @default false
       */
      skipOrthophoto: z.boolean().optional(),

      /**
       * Skip generation of PDF report. This can save time if you don't need a report.
       *
       * @default false
       */
      skipReport: z.boolean().optional(),

      /**
       * Export the georeferenced point cloud in CSV format.
       *
       * @default false
       */
      pcCsv: z.boolean().optional(),

      /**
       * Export the georeferenced point cloud in LAS format.
       *
       * @default false
       */
      pcLas: z.boolean().optional(),

      /**
       * Export the georeferenced point cloud in Entwine Point Tile (EPT) format.
       *
       * @default false
       */
      pcEpt: z.boolean().optional(),

      /**
       * Save the georeferenced point cloud in Cloud Optimized Point Cloud (COPC) format.
       *
       * @default false
       */
      pcCopc: z.boolean().optional(),

      /**
       * Build a DTM (Digital Terrain Model, ground only) using a simple morphological filter.
       * Check the --dem* and --smrf* parameters for finer tuning.
       *
       * @default false
       */
      dtm: z.boolean().optional(),

      /**
       * Build a DSM (Digital Surface Model, ground + objects) using a progressive morphological filter.
       * Check the --dem* parameters for finer tuning.
       *
       * @default false
       */
      dsm: z.boolean().optional(),

      /**
       * Set this parameter if you want a striped GeoTIFF.
       *
       * @default false
       */
      orthophotoNoTiled: z.boolean().optional(),

      /**
       * Set this parameter if you want to generate a PNG rendering of the orthophoto.
       *
       * @default false
       */
      orthophotoPng: z.boolean().optional(),

      /**
       * Set this parameter if you want to generate a Google Earth (KMZ) rendering of
       * the orthophoto.
       *
       * @default false
       */
      orthophotoKmz: z.boolean().optional(),

      /**
       * Set the compression to use for orthophotos. Can be one of: JPEG, LZW, PACKBITS,
       * DEFLATE, LZMA, NONE.
       *
       * @default OrthophotoCompression.DEFLATE
       */
      orthophotoCompression: OrthophotoCompressionSchema.optional(),

      /**
       * Generate static tiles for orthophotos and DEMs that are suitable for viewers
       * like Leaflet or OpenLayers.
       *
       * @default false
       */
      tiles: z.boolean().optional(),

      /**
       * Generate Open Geospatial Consortium (OGC) 3D tiles outputs.
       * https://www.ogc.org/standard/3dtiles/
       *
       * @default false
       */
      threeDTiles: z.boolean().optional(),

      /**
       * Create Cloud-Optimized GeoTIFFs instead of normal GeoTIFFs.
       *
       * @default false
       */
      cog: z.boolean().optional(),

      /**
       * Build orthophoto overviews for faster display in programs such as QGIS.
       *
       * @default false
       */
      buildOverviews: z.boolean().optional(),

      /**
       * Generate single file Binary glTF (GLB) textured models.
       *
       * @default false
       */
      gltf: z.boolean().optional(),
    })
    .optional(),

  splitMerge: z
    .object({
      /**
       * Choose what to merge in the merge step in a split dataset. By default all available outputs
       * are merged. Options: all, pointcloud, orthophoto, dem.
       *
       * @default Merge.ALL
       */
      merge: MergeSchema.optional(),

      /**
       * Skip alignment of submodels in split-merge. Useful if GPS is good enough on very large
       * datasets.
       *
       * @default false
       */
      smNoAlignment: z.boolean().optional(),

      /**
       * Average number of images per submodel. When splitting a large dataset
       * into smaller submodels, images are grouped into clusters. This value
       * regulates the number of images that each cluster should have on average.
       *
       * @default 999999
       */
      split: z.number().optional(),

      /**
       * Radius of the overlap between submodels. After grouping images into
       * clusters, images that are closer than this radius to a cluster are added
       * to the cluster. This is done to ensure that neighboring submodels overlap.
       *
       * @default 150
       */
      splitOverlap: z.number().optional(),

      /**
       * Path to the image groups file that controls how images should be split into
       * groups. The file needs to use the following format: image_name group_name
       */
      splitImageGroups: z.string().optional(),

      /**
       * URL to a ClusterODM instance for distributing a split-merge workflow
       * on multiple nodes in parallel.
       */
      clusterODMUrl: z.string().optional(),
    })
    .optional(),
})

export type OdmSettings = z.infer<typeof OdmSettingsSchema>
