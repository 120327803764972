/**
 * The following constructs are adapted/borrowed from the ideas in this blog post:
 *
 * https://medium.com/with-orus/the-5-commandments-of-clean-error-handling-in-typescript-93a9cbdf1af5
 *
 */

/**
 * When running typescript in strict mode the return type of a try-catch block
 * error moves from 'any' to 'unknown'. This function ensures that the error
 * thrown is an instance of Error and if not, it creates a new Error instance
 * and reduces boilerplate code in try-catch blocks.
 */
export const ensureError = (value: unknown): Error => {
  if (value instanceof Error) return value

  let stringified = '[Unable to stringify the thrown value]'
  try {
    stringified = JSON.stringify(value)
  } catch {
    /* leave stringified with original value */
  }

  const error = new Error(
    `This value was thrown as is, not through an Error: ${stringified}`,
  )
  return error
}

type Jsonable =
  | string
  | number
  | boolean
  | null
  | undefined
  | readonly Jsonable[]
  | { readonly [key: string]: Jsonable }
  | { toJSON(): Jsonable }

/**
 * A custom DroidmapError class that captures additional context information.
 */
export class DroidMapError extends Error {
  public readonly context?: Jsonable

  constructor(
    message: string,
    options: { cause?: Error; context?: Jsonable } = {},
  ) {
    const { cause, context } = options

    super(message, { cause })
    this.name = this.constructor.name

    this.context = context
  }
}

/**
 * A custom error type for when a entity is not found.
 */
export class EntityNotFoundError extends DroidMapError {
  constructor(
    message: string,
    options: { cause?: Error; context?: Jsonable } = {},
  ) {
    super(message, options)
  }
}
