import WorkerPool from './workerPool'

interface ThumbnailResponse {
  thumbnail: string | null
}

const workerPool = new WorkerPool(16)

/**
 * Creates a thumbnail for the given file.
 *
 * @param {File} file - The file to create a thumbnail for.
 * @param {number} width - The width of the thumbnail.
 * @param {(thumbnail: string | null) => void} onComplete - Callback function to handle the generated thumbnail.
 */
export const createThumbnail = (
  file: File,
  width: number,
  onComplete: (thumbnail: string | null) => void,
) => {
  workerPool.addTask<ThumbnailResponse>(
    './scripts/thumbnailWorkerScript.ts',
    { file, width },
    (res) => {
      onComplete(res.thumbnail)
    },
  )
}
