import {
  CheckCircleIcon,
  ClockIcon,
  MinusCircleIcon,
  PlayCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { cva } from 'class-variance-authority'

import { Status } from './Status'

interface StatusIconProps {
  status: Status
  className?: string
}

const statusIconClasses = cva('w-5 h-5', {
  variants: {
    status: {
      [Status.Success]: `text-status-success`,
      [Status.Pending]: `text-status-pending`,
      [Status.Running]: `text-status-running`,
      [Status.Error]: `text-status-error`,
      [Status.Cancelled]: `text-status-cancelled`,
    },
  },
})

export const StatusIcon: React.FC<
  StatusIconProps & React.SVGProps<SVGSVGElement>
> = ({ status, className = '', ...props }) => {
  const classes = statusIconClasses({ status, className })

  switch (status) {
    case Status.Success:
      return <CheckCircleIcon className={classes} {...props} />
    case Status.Pending:
      return <ClockIcon className={classes} {...props} />
    case Status.Running:
      return <PlayCircleIcon className={classes} {...props} />
    case Status.Error:
      return <XCircleIcon className={classes} {...props} />
    case Status.Cancelled:
      return <MinusCircleIcon className={classes} {...props} />
    default:
      return null
  }
}
