import { ColumnDef } from '@tanstack/react-table'

import { Organisation } from '@droidmap/organisation-service-contract'
import { StorageSummary } from '@droidmap/shared/service-contract'

import { Button } from '../../components/Button'

export const columns: ColumnDef<Organisation, keyof Organisation>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'hasPaymentMethod',
    header: 'Payment Method',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'monthlySpendCurrent',
    header: 'Current Monthly Spend',
    cell: ({ row }) => `$${row.original.monthlySpendCurrent.toFixed(2)}`,
  },
  {
    accessorKey: 'monthlySpendLimit',
    header: 'Monthly Spend Limit',
    cell: ({ row }) => `$${row.original.monthlySpendLimit.toFixed(2)}`,
  },
  {
    accessorKey: 'storageSummary',
    header: 'Storage Summary',
    cell: ({ row }) => {
      const storageSummary: StorageSummary = row.original.storageSummary
      const sum = Object.values(storageSummary).reduce(
        (acc, value) => acc + value,
        0,
      )
      return sum
    },
  },
  {
    accessorKey: 'createdBy',
    header: 'Created By',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'created',
    header: 'Created At',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    accessorKey: 'updatedBy',
    header: 'Updated By',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'updated',
    header: 'Updated At',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row, table }) => {
      const { onDelete, onView } = table.options.meta as {
        onDelete: (id: string) => void
        onView: (id: string) => void
      }
      return (
        <div className="flex space-x-2">
          <Button onClick={() => onView(row.original.id)} size="sm">
            View
          </Button>
          <Button
            onClick={() => onDelete(row.original.id)}
            size="sm"
            variant="destructive"
          >
            Delete
          </Button>
        </div>
      )
    },
  },
]
