import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'

import {
  AssetSetType,
  AssetType,
  CreateAssetInput,
  CreateAssetSetInput,
} from '@droidmap/asset-service-contract'

import { useClient } from '../context/ClientContext'
import uploadFileWithProgress from '../lib/fileUpload'

type FileWithProgress = {
  file: File
  progress: number
  rate: number
  status: 'pending' | 'uploading' | 'completed' | 'error'
}

type UseAssetUploadOptions = {
  name: string
  projectId: string
  assetSetType: AssetSetType
}

/**
 * Custom hook for asset upload.
 *
 * @param options - The options for asset upload.
 * @returns An object containing the files with progress, handleFileChange function, and handleUpload function.
 */
export const useAssetUpload = (options: UseAssetUploadOptions) => {
  const [filesWithProgress, setFilesWithProgress] = useState<
    FileWithProgress[]
  >([])
  const queryClient = useQueryClient()
  const { assetClient } = useClient()

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const fileArray = Array.from(event.target.files).map((file) => ({
          file,
          progress: 0,
          rate: 0,
          status: 'pending',
        }))
        setFilesWithProgress(fileArray as FileWithProgress[])
      }
    },
    [],
  )

  const assetSetMutation = useMutation({
    mutationFn: async (createAssetSetInput: CreateAssetSetInput) => {
      return await assetClient.createAssetSet(createAssetSetInput)
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['assetSets', options.projectId],
      })
    },
  })

  const assetMutation = useMutation({
    mutationFn: async (assetMutationInput: {
      createAssetInput: CreateAssetInput
      uploadOptions: {
        file: File
        updateProgress: (
          percentage: number,
          rate: number,
          status: string,
        ) => void
      }
    }) => {
      const asset = await assetClient.createAsset(
        assetMutationInput.createAssetInput,
      )
      console.log(`Created: ${JSON.stringify(asset)}`)
      return await uploadFileWithProgress(
        asset.presignedUrl,
        assetMutationInput.uploadOptions.file,
        assetMutationInput.uploadOptions.updateProgress,
      )
    },
    onSettled: () => {
      console.log('Settled')
      queryClient.invalidateQueries({
        queryKey: ['assets', assetSetMutation.data?.id],
      })
    },
  })

  useEffect(() => {
    if (
      filesWithProgress.length > 0 &&
      filesWithProgress.every((file) => file.status === 'completed')
    ) {
      console.log('All files completed')
    }
  }, [filesWithProgress])

  const handleUpload = useCallback(async () => {
    const createAssetSetInput: CreateAssetSetInput = {
      name: options.name,
      projectId: options.projectId,
      assetSetType: options.assetSetType,
    }

    assetSetMutation.mutate(createAssetSetInput, {
      onSuccess(assetSet) {
        console.log(`Created: ${JSON.stringify(assetSet)}`)

        filesWithProgress.forEach(async (fileWithProgress, index) => {
          setFilesWithProgress((prevFiles) => {
            const newFiles = [...prevFiles]
            newFiles[index].status = 'pending'
            return newFiles
          })

          const updateProgress = (
            percentage: number,
            rate: number,
            status: string,
          ) => {
            setFilesWithProgress((prevFiles) => {
              const newFiles = [...prevFiles]
              newFiles[index].progress = percentage
              newFiles[index].rate = rate
              newFiles[index].status = status as FileWithProgress['status']
              return newFiles
            })
          }

          const createAssetInput: CreateAssetInput = {
            name: fileWithProgress.file.name,
            assetSetId: assetSet.id,
            //TODO - get the correct asset type from the file
            assetType: AssetType.Jpeg,
          }

          assetMutation.mutate({
            createAssetInput,
            uploadOptions: {
              file: fileWithProgress.file,
              updateProgress,
            },
          })
        })
      },
    })
  }, [assetMutation, assetSetMutation, filesWithProgress, options])

  return {
    filesWithProgress,
    handleFileChange,
    handleUpload,
  }
}
