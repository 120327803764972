import React from 'react'

import { formatBits } from '../../lib/utils'
import { Button } from '../Button'
import ImageCard from '../ImageCard'

type FileWithProgress = {
  file: File
  progress: number
  rate: number
  status: 'pending' | 'uploading' | 'completed' | 'error'
}

type MultipleFileUploadProps = {
  useFileUpload: () => {
    filesWithProgress: FileWithProgress[]
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleUpload: () => Promise<void>
  }
}

const MultiFileUpload: React.FC<MultipleFileUploadProps> = ({
  useFileUpload,
}) => {
  const { filesWithProgress, handleFileChange, handleUpload } = useFileUpload()

  return (
    <div>
      <div className="mb-4">
        <input
          type="file"
          id="fileInput"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
          <Button onClick={() => document.getElementById('fileInput')?.click()}>
            Select Files
          </Button>
        </label>
        <Button
          onClick={handleUpload}
          disabled={filesWithProgress.length === 0}
          variant="creative"
          className="mx-2"
        >
          Upload Files
        </Button>
      </div>
      {filesWithProgress.length > 0 && (
        <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-5">
          {filesWithProgress.map((fileWithProgress, index) => (
            <ImageCard
              key={index}
              file={fileWithProgress.file}
              title={fileWithProgress.file.name}
              body={
                <div>
                  <p className="text-left text-xs font-medium text-white sm:text-sm">
                    {fileWithProgress.status} -{' '}
                    {fileWithProgress.progress.toFixed(2)}% (
                    {formatBits(fileWithProgress.rate * 8)}/s)
                  </p>
                  <div
                    style={{
                      width: '100%',
                      height: '10px',
                      backgroundColor: '#f0f0f0',
                      marginTop: '5px',
                      borderRadius: '5px',
                    }}
                  >
                    <div
                      style={{
                        width: `${fileWithProgress.progress}%`,
                        height: '100%',
                        backgroundColor: '#007bff',
                        borderRadius: '5px',
                      }}
                    ></div>
                  </div>
                </div>
              }
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default MultiFileUpload
