import { QueryParameterBag } from '@smithy/types'

import { AwsCredentialIdentity } from '@aws-sdk/types'

import {
  AwsHttpHeaders,
  DroidMapError,
  DroidMapHttpHeaders,
  W3CTraceContextHttpHeaders,
  retryFetch,
  toParameterString,
} from '@droidmap/shared/utils'

import { DroidMapMetadata } from './service-events'

export type ServiceClientContext = {
  getToken: () => Promise<string>
  endpoint: string
  isInternal?: boolean
  extraHeaders?: Record<string, string>
  serviceCredentials?: AwsCredentialIdentity
  droidMapMetadata?: DroidMapMetadata
}

export class ServiceClient {
  public readonly serviceName: string
  public getToken: () => Promise<string>
  public readonly endpoint: string
  public readonly isInternal: boolean
  protected readonly extraHeaders: Record<string, string>
  public readonly serviceCredentials?: AwsCredentialIdentity
  private droidMapMetadata?: DroidMapMetadata

  constructor(input: ServiceClientContext & { serviceName: string }) {
    this.serviceName = input.serviceName
    this.getToken = input.getToken
    this.endpoint = input.endpoint
    this.isInternal = input.isInternal || false
    this.extraHeaders = input.extraHeaders || {}
    this.serviceCredentials = input.serviceCredentials
    this.droidMapMetadata = input.droidMapMetadata
  }

  protected callApiRouteWithToken = async <T, U>(input: {
    method: string
    path: string
    queryParameters?: QueryParameterBag
    body?: T
    metadata?: DroidMapMetadata
  }): Promise<U> => {
    // If the metadata in the input is not set, use the metadata from the client
    // This allows us to set the metadata once on the client and not have to pass
    // it in every call which is useful for the frontend where the metadata changes
    // infrequently. While still allowing the metadata to be set on a per call basis
    // which is need for internal service calls.
    const metadata = input.metadata || this.droidMapMetadata
    if (!metadata) {
      throw new DroidMapError('No DroidMapMetadata provided for service call')
    }

    const token = await this.getToken()

    const response = await retryFetch(() =>
      this.buildRequest(input, metadata, token),
    )

    if (response.ok) {
      return (await response.json()) as U
    }

    // You need to consume the response body for failed requests in order to reuse the connection
    await response.text()
    throw new Error(
      `Failed to call api route ${input.method} ${input.path} - ${response.status} ${response.statusText}`,
    )
  }

  /**
   * Builds up a request object for the fetch api
   *
   * @param input
   * @param token
   * @returns
   */
  private buildRequest = <T>(
    input: {
      method: string
      path: string
      queryParameters?: QueryParameterBag
      body?: T
    },
    metadata: DroidMapMetadata,
    token: string,
  ): Request => {
    const { method, path, queryParameters, body } = input

    let url = `${this.endpoint}${path}`
    if (queryParameters) {
      // add query params to url
      url = `${url}?${toParameterString(queryParameters)}`
    }

    // Auth headers
    const headers = this.buildRequestHeaders(token, metadata)

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      body: method === 'GET' ? undefined : JSON.stringify(body),
    }

    return new Request(url, options)
  }

  buildRequestHeaders(token: string, metadata: DroidMapMetadata) {
    const authHeaders = {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers':
        'Authorization,Content-Type,Access-Control-Allow-Origin,x-droidmap-org,x-droidmap-on-behalf-of,x-droidmap-internal,x-amzn-trace-id,traceparent,tracestate',
    }

    const droidmapHeaders = this.buildDroidMapHeaders(metadata)
    const traceHeaders = this.buildTraceHeaders(metadata)

    const headers = {
      ...this.extraHeaders,
      ...authHeaders,
      ...droidmapHeaders,
      ...traceHeaders,
    }
    return headers
  }

  buildDroidMapHeaders = (
    metadata: DroidMapMetadata,
  ): Record<string, string> => {
    const headers: Record<string, string> = {}

    if (metadata) {
      if (this.isInternal) {
        headers[DroidMapHttpHeaders.X_DROIDMAP_INTERNAL] = 'true'
        headers[DroidMapHttpHeaders.X_DROIDMAP_ON_BEHALF_OF] = metadata.userId
      }
      headers[DroidMapHttpHeaders.X_DROIDMAP_ORG] = metadata.orgId
    } else {
      throw new DroidMapError('No DroidMapMetadata provided for service call')
    }

    return headers
  }

  buildTraceHeaders = (metadata: DroidMapMetadata): Record<string, string> => {
    const headers: Record<string, string> = {}

    if (metadata) {
      if (metadata.xrayTraceId) {
        headers[AwsHttpHeaders.X_AMZN_TRACE_ID] = metadata.xrayTraceId
      }
      if (metadata.traceParent) {
        headers[W3CTraceContextHttpHeaders.TRACE_PARENT] = metadata.traceParent
      }
      if (metadata.traceState) {
        headers[W3CTraceContextHttpHeaders.TRACE_STATE] = metadata.traceState
      }
    }

    return headers
  }

  private sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  public changeActiveOrg = (orgId: string) => {
    if (this.droidMapMetadata) {
      this.droidMapMetadata = {
        userId: this.droidMapMetadata.userId,
        orgId,
      }
    } else {
      throw new DroidMapError('No DroidMapMetadata currently set for client')
    }
  }

  public getDroidMapMetadata = () => {
    return this.droidMapMetadata
  }
}
