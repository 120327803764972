import React from 'react'

import { Button } from '../Button'

interface DetailCardProps {
  title: string
  image: string
  details: string[]
  onClick?: () => void
}

const DetailCard: React.FC<DetailCardProps> = ({
  title,
  image,
  details,
  onClick,
}) => {
  return (
    <div className="aspect-h-1 aspect-w-1 group relative overflow-hidden rounded-lg shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
      <img
        src={image}
        alt=""
        className="pointer-events-none object-cover group-hover:opacity-80"
      />
      <div className="absolute inset-0 mt-auto h-1/3 flex-col bg-black bg-opacity-100  px-2 pt-1 opacity-75 transition-opacity group-hover:opacity-60">
        <p className="text-left text-sm font-bold text-white  sm:text-base ">
          {title}
        </p>
        {details.map((detail, index) => (
          <p
            key={index}
            className="text-left text-xs font-medium text-white sm:text-sm"
          >
            {detail}
          </p>
        ))}
      </div>
      <Button onClick={onClick} className="absolute inset-0 focus:outline-none">
        View details for {title}
      </Button>
    </div>
  )
}

export default DetailCard
