import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { confirmSignUp } from '@aws-amplify/auth'

import AuthForm from '../../components/AuthForm'
import AuthPage from '../../components/AuthPage'
import { Button } from '../../components/Button'
import ErrorMessage from '../../components/ErrorMessage'
import Input from '../../components/Input'
import { useAuth } from '../../context/AuthContext'
import { useFormData } from '../../hooks/useFormData'

type ConfirmationDetails = {
  username: string
  confirmationCode: string
}

const initialConfirmationDetails: ConfirmationDetails = {
  username: '',
  confirmationCode: '',
}

export default function ConfirmSignUp() {
  const auth = useAuth()
  const navigate = useNavigate()
  const [confirmationDetails, handleConfirmationDetailsChange] = useFormData(
    initialConfirmationDetails,
  )
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError('')
    setLoading(true)

    try {
      const { nextStep } = await confirmSignUp(confirmationDetails)
      if (nextStep?.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
        const signInOutput = await auth.autoSignIn()
        if (signInOutput.isSignedIn) {
          navigate('/profile')
          return
        }
      }
      navigate('/login')
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred')
    } finally {
      setLoading(false)
    }
  }

  const inputFields = [
    {
      name: 'username',
      label: 'Username',
      type: 'text',
      placeholder: 'Username',
    },
    {
      name: 'confirmationCode',
      label: 'Confirmation code',
      type: 'text',
      placeholder: 'Confirmation code',
    },
  ]

  return (
    <AuthPage title={'Confirm Signup'}>
      <AuthForm onSubmit={handleSubmit}>
        {inputFields.map(({ label, name, type, placeholder }) => (
          <Input
            key={name}
            label={label}
            type={type}
            name={name}
            placeholder={placeholder}
            value={confirmationDetails[name as keyof ConfirmationDetails]}
            required
            onChange={handleConfirmationDetailsChange}
          />
        ))}

        {error && <ErrorMessage content={error} />}

        <Button
          type="submit"
          className="w-full"
          isLoading={loading}
          disabled={loading}
        >
          Confirm
        </Button>
      </AuthForm>
    </AuthPage>
  )
}
