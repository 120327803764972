import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Sidebar } from '../layouts/sidebar'
import { useAuth } from './AuthContext'

type Props = {
  children?: React.ReactNode
}

export const PrivateRoute: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth()
  const location = useLocation()

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  return <Sidebar>{children}</Sidebar>
}

export default PrivateRoute
