import {
  DroidMapMetadata,
  ServiceClient,
  ServiceClientContext,
} from '@droidmap/shared/service-contract'

import { GetUserInput, UpdateUserInput, User } from '../models/user'

export class UserServiceClient extends ServiceClient {
  public static readonly SERVICE_NAME = 'UserService'

  constructor(input: ServiceClientContext) {
    super({ ...input, serviceName: UserServiceClient.SERVICE_NAME })
  }

  getUser = async (
    getUserInput: GetUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<User> => {
    const user = await this.callApiRouteWithToken<undefined, User>({
      method: 'GET',
      path: `/users/${getUserInput.id}`,
      metadata,
    })

    return user
  }

  updateUser = async (
    updateUserInput: UpdateUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<User> => {
    const user = await this.callApiRouteWithToken<Partial<User>, User>({
      method: 'PATCH',
      path: `/users/${updateUserInput.id}`,
      body: updateUserInput,
      metadata,
    })

    return user
  }
}
