import { useContext } from 'react'

import LogoLight from '../../assets/logo_color.svg?react'
import LogoDark from '../../assets/logo_mixed.svg?react'
import { ThemeContext } from '../../context/ThemeContext'

export default function Logo({ className }: { className?: string }) {
  const { theme } = useContext(ThemeContext)
  return theme === 'dark' ? (
    <LogoDark className={className} color="rgba(var(--color-primary)" />
  ) : (
    <LogoLight className={className} color="rgba(var(--color-primary)" />
  )
}
