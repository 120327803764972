import { OdmSettings } from '@droidmap/shared/odm-settings'
import { Optional } from '@droidmap/shared/service-contract'

export enum TaskType {
  Photogrammetry = 'photogrammetry',
}
export interface Task {
  id: string

  /**
   * The id of the project that this task belongs to
   */
  projectId: string

  /**
   * The name of the task
   */
  name: string

  /**
   * The type of the task
   */
  type: TaskType

  /**
   * The id of the reference to the task in the external service
   */
  referenceId?: string

  /**
   * The time the entity was created. This attribute is automatically
   * set by the dynamodb-onetable library.
   */
  created: Date

  /**
   * The user id of the user who created this entity.
   */
  createdBy: string

  /**
   * The last time this entity was modified. This attribute is automatically
   * set by the dynamodb-onetable library.
   */
  updated: Date

  /**
   * The user id of the user who last updated this entity.
   */
  updatedBy: string
}

export type CreateTaskInput = Omit<
  Task,
  'id' | 'created' | 'createdBy' | 'updated' | 'updatedBy'
>

export type GetTaskInput = {
  taskId: string
}

export type UpdateTaskInput = Optional<
  Omit<
    Task,
    | 'id'
    | 'created'
    | 'createdBy'
    | 'updated'
    | 'updatedBy'
    | 'projectId'
    | 'referenceId'
    | 'type'
  >,
  'name'
> & {
  taskId: string
}

export type DeleteTaskInput = {
  taskId: string
}

export type ListTasksByProjectInput = {
  projectId: string
}

export type ComputeResources = {
  vCpus: number
  memory: number
}

export type CreatePhotogrammetryTaskInput = CreateTaskInput & {
  inputAssetSets: string[]
  outputAssetSets: string[]
  odmSettings?: OdmSettings
  computeResourcesOverride?: ComputeResources
}

export type CreatePhotogrammetryTaskEventInput = {
  taskId: string
  inputAssetSets: string[]
  outputAssetSets: string[]
  odmSettings?: OdmSettings
  computeResourcesOverride?: ComputeResources
}

export type TaskReference = {
  taskId: string
  referenceId: string
}
