import {
  DroidMapMetadata,
  ServiceClient,
  ServiceClientContext,
} from '@droidmap/shared/service-contract'

import {
  DeleteMappingJobInput,
  GetMappingJobInput,
  MappingJob,
} from '../models/mapping-job'
import {
  CreateMappingJobStepInput,
  DeleteMappingJobStepInput,
  GetMappingJobStepInput,
  MappingJobStep,
} from '../models/mapping-job-step'

export class MappingJobServiceClient extends ServiceClient {
  public static readonly SERVICE_NAME = 'MappingJobService'

  constructor(input: ServiceClientContext) {
    super({ ...input, serviceName: MappingJobServiceClient.SERVICE_NAME })
  }

  getMappingJob = async (
    input: GetMappingJobInput,
    metadata?: DroidMapMetadata,
  ): Promise<MappingJob> => {
    const mappingJob = await this.callApiRouteWithToken<string, MappingJob>({
      method: 'GET',
      path: `/mapping-jobs/${input.mappingJobId}`,
      metadata,
    })

    return mappingJob
  }

  deleteMappingJob = async (
    input: DeleteMappingJobInput,
    metadata?: DroidMapMetadata,
  ): Promise<MappingJob> => {
    const mappingJob = await this.callApiRouteWithToken<string, MappingJob>({
      method: 'DELETE',
      path: `/mapping-jobs/${input.mappingJobId}`,
      metadata,
    })

    return mappingJob
  }

  createMappingJobStep = async (
    input: CreateMappingJobStepInput,
    metadata?: DroidMapMetadata,
  ): Promise<MappingJobStep> => {
    const mappingJobStep = await this.callApiRouteWithToken<
      CreateMappingJobStepInput,
      MappingJobStep
    >({
      method: 'POST',
      path: '/mapping-job-steps',
      body: input,
      metadata,
    })

    return mappingJobStep
  }

  getMappingJobStep = async (
    input: GetMappingJobStepInput,
    metadata?: DroidMapMetadata,
  ): Promise<MappingJobStep> => {
    const mappingJobStep = await this.callApiRouteWithToken<
      string,
      MappingJobStep
    >({
      method: 'GET',
      path: `/mapping-job-steps/${input.mappingJobStepId}`,
      metadata,
    })

    return mappingJobStep
  }

  deleteMappingJobStep = async (
    input: DeleteMappingJobStepInput,
    metadata?: DroidMapMetadata,
  ): Promise<MappingJobStep> => {
    const mappingJobStep = await this.callApiRouteWithToken<
      string,
      MappingJobStep
    >({
      method: 'DELETE',
      path: `/mapping-job-steps/${input.mappingJobStepId}`,
      metadata,
    })

    return mappingJobStep
  }
}
