import { Amplify } from 'aws-amplify'
import { AuthError } from 'aws-amplify/auth'
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import * as Auth from '@aws-amplify/auth'

import { Organisation } from '@droidmap/organisation-service-contract'
import { StandardOrgIds } from '@droidmap/shared/service-contract'
import { User } from '@droidmap/user-service-contract'

import { cognitoConfig } from '../../cognitoConfig'
import {
  createStandaloneOrganisationClient,
  createStandaloneUserServiceClient,
} from './ClientContext'
import { useConfig } from './ConfigContext'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: cognitoConfig.UserPoolId,
      userPoolClientId: cognitoConfig.ClientId,
      loginWith: {
        username: true,
        email: true,
      },
      signUpVerificationMethod: 'code',
      userAttributes: {
        email: {
          required: true,
        },
        given_name: {
          required: true,
        },
        family_name: {
          required: true,
        },
      },
    },
  },
})

// Enum for the different steps in the sign in process
enum SignInStep {
  DONE = 'DONE',
  CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
  CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE',
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE',
  CONFIRM_SIGN_IN_WITH_TOTP_CODE = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE',
  CONTINUE_SIGN_IN_WITH_TOTP_SETUP = 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP',
  CONTINUE_SIGN_IN_WITH_MFA_SELECTION = 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION',
}

interface AuthState {
  user: Auth.AuthUser | null
  username: string
  userDetails: User
  activeOrganisation: Organisation
  isLoading: boolean
  isAuthenticated: boolean
}

export type UserSignUpInput = {
  username: string
  password: string
  email: string
  firstName: string
  lastName: string
}

export type UserSignInInput = {
  username: string
  password: string
}

interface AuthActions {
  signIn: (userDetails: UserSignInInput) => Promise<Result>
  signOut: () => Promise<Result>
  signUp: (userDetails: UserSignUpInput) => Promise<Result>
  autoSignIn: () => Promise<Auth.SignInOutput>
  getAccessToken: () => Promise<string>
  getIdToken: () => Promise<string>
  getDroidmapId: () => Promise<string>
  forgotPassword: (username: string) => Promise<Result>
  confirmPassword: (
    username: string,
    code: string,
    newPassword: string,
  ) => Promise<Result>
}

type UseAuth = AuthState & AuthActions

interface Result {
  success: boolean
  message?: string
  nextStep?: string
}

export const AuthContext = createContext<UseAuth | undefined>(undefined)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = (): UseAuth => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

const useProvideAuth = (): UseAuth => {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    username: '',
    userDetails: {} as User,
    activeOrganisation: {} as Organisation,
    isLoading: true,
    isAuthenticated: false,
  })
  const { ENV } = useConfig()

  const updateAuthState = (newState: Partial<AuthState>) => {
    setAuthState((prevState) => ({ ...prevState, ...newState }))
  }

  const getCurrentUser = async () => {
    let user: Auth.AuthUser | null = null
    try {
      user = await Auth.getCurrentUser()
      const userId = await getDroidmapId()
      const details = await getUserDetails(getAccessToken, userId)
      const org = await getActiveOrganisation(getAccessToken, userId)

      updateAuthState({
        user,
        username: user.username,
        userDetails: details,
        activeOrganisation: org,
        isLoading: false,
        isAuthenticated: true,
      })
    } catch (err) {
      updateAuthState({
        user: null,
        username: '',
        isLoading: false,
        isAuthenticated: false,
      })
      if (err instanceof AuthError) {
        // User is not signed in
        return
      }
      console.error('Failed to fetch current user:', err)
      throw new Error('Failed to fetch current user')
    }
  }

  const getUserDetails = async (
    getToken: () => Promise<string>,
    userId: string,
  ): Promise<User> => {
    const client = createStandaloneUserServiceClient(
      getToken,
      userId,
      StandardOrgIds.SYSTEM,
      ENV,
    )
    return (await client.getUser({ id: userId })) || ({} as User)
  }

  const getActiveOrganisation = async (
    getToken: () => Promise<string>,
    userId: string,
  ) => {
    const client = createStandaloneOrganisationClient(
      getToken,
      userId,
      StandardOrgIds.SYSTEM,
      ENV,
    )

    const orgs = await client.listOrganisationsByUser({
      userId,
    })

    if (!orgs.items?.length) {
      throw new Error('No organisations found for user')
    }

    return orgs.items?.[0]
  }

  useEffect(() => {
    getCurrentUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const signIn: AuthActions['signIn'] = async (
    userDetails: UserSignInInput,
  ) => {
    try {
      const { username, password } = userDetails
      const signInResponse = await Auth.signIn({ username, password })

      switch (signInResponse.nextStep.signInStep) {
        case SignInStep.DONE:
          await getCurrentUser()
          return { success: true }
        case SignInStep.CONFIRM_SIGN_UP:
          // TODO - handle confirmation required properly
          return { success: false, message: 'Confirmation required' }
        case SignInStep.RESET_PASSWORD:
          return { success: false, message: 'New password required' }
        default:
          return { success: false, message: signInResponse.nextStep.signInStep }
      }
    } catch (err) {
      return { success: false, message: (err as Error).message }
    }
  }

  const signUp = async (userDetails: UserSignUpInput) => {
    const { username, password, email, firstName, lastName } = userDetails
    try {
      const signUpResponse = await Auth.signUp({
        username,
        password,
        options: {
          userAttributes: {
            given_name: firstName,
            family_name: lastName,
            email: email,
          },
          autoSignIn: true,
        },
      })

      switch (signUpResponse.nextStep.signUpStep) {
        case SignInStep.DONE:
          await getCurrentUser()
          return { success: true, nextStep: signUpResponse.nextStep.signUpStep }
        case SignInStep.CONFIRM_SIGN_UP:
          return { success: true, nextStep: signUpResponse.nextStep.signUpStep }
        default:
          return {
            success: false,
            nextStep: signUpResponse.nextStep.signUpStep,
          }
      }
    } catch (err) {
      return { success: false, message: (err as Error).message }
    }
  }

  const autoSignIn = async () => {
    try {
      const signInOutput = await Auth.autoSignIn()
      if (signInOutput.isSignedIn) {
        await getCurrentUser()
      }
      return signInOutput
    } catch (err) {
      console.error('Failed to auto sign in:', err)
      throw new Error('Failed to auto sign in')
    }
  }

  const signOut: AuthActions['signOut'] = async () => {
    try {
      await Auth.signOut()
      updateAuthState({
        user: null,
        username: '',
        userDetails: {} as User,
        activeOrganisation: {} as Organisation,
        isLoading: false,
        isAuthenticated: false,
      })
      return { success: true }
    } catch (err) {
      return { success: false, message: (err as Error).message }
    }
  }

  const forgotPassword: AuthActions['forgotPassword'] = async (username) => {
    try {
      await Auth.resetPassword({ username })
      return { success: true }
    } catch (err) {
      return { success: false, message: (err as Error).message }
    }
  }

  const confirmPassword: AuthActions['confirmPassword'] = async (
    username,
    confirmationCode,
    newPassword,
  ) => {
    try {
      await Auth.confirmResetPassword({
        username,
        confirmationCode,
        newPassword,
      })
      return { success: true }
    } catch (err) {
      return { success: false, message: (err as Error).message }
    }
  }

  const getAccessToken: AuthActions['getAccessToken'] = async () => {
    try {
      const session = await Auth.fetchAuthSession()
      return session.tokens?.accessToken.toString() || ''
    } catch (err) {
      console.error('Failed to fetch access token:', err)
      throw new Error('Failed to fetch access token')
    }
  }

  const getIdToken: AuthActions['getIdToken'] = async () => {
    try {
      const session = await Auth.fetchAuthSession()
      return session.identityId || ''
    } catch (err) {
      console.error('Failed to fetch id token:', err)
      throw new Error('Failed to fetch id token')
    }
  }

  const getDroidmapId: AuthActions['getDroidmapId'] = async () => {
    try {
      const session = await Auth.fetchAuthSession()
      return (session.tokens?.accessToken.payload['userId'] as string) || ''
    } catch (err) {
      console.error('Failed to fetch droidmap id:', err)
      throw new Error('Failed to fetch droidmap id')
    }
  }

  return {
    ...authState,
    signIn,
    signOut,
    signUp,
    autoSignIn,
    getAccessToken,
    getIdToken,
    getDroidmapId,
    forgotPassword,
    confirmPassword,
  }
}
