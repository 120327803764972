import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import {
  FieldValues,
  Path,
  PathValue,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form'

import { AssetSet, AssetSetType } from '@droidmap/asset-service-contract'
import { TaskType } from '@droidmap/project-service-contract'
import {
  OdmSettings,
  calculateJobSettings,
} from '@droidmap/shared/odm-settings'
import { pascalToSentence } from '@droidmap/shared/utils'

import { useClient } from '../../context/ClientContext'
import { formatBytes, getDirtyValues } from '../../lib/utils'
import { Button } from '../Button'
import { SelectController, TextController } from '../FormController'
import { FormSections } from './FormSections'

export interface OdmFormProps<T extends FieldValues> {
  assetSets: AssetSet[]
  form: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
}

export const OdmForm = <T extends FieldValues>({
  assetSets,
  form,
  onSubmit,
}: OdmFormProps<T>) => {
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = form
  const [onCreate, setOnCreate] = useState(false)
  const assetSetId = form.watch('droidmap.assetSetId' as Path<T>)

  const { assetClient } = useClient()

  const { data: compute } = useQuery({
    queryKey: ['compute', assetSetId, form.getValues()],
    queryFn: () =>
      calculateJobSettings(assetClient, [assetSetId], form.getValues()),
    enabled: !!assetSetId && form.formState.isValid && onCreate,
  })

  return (
    <form
      onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
      className="space-y-6"
    >
      {onCreate ? (
        <div>
          <div className="grid text-element-1 gap-y-2">
            <div>
              <p className="font-bold">Estimated Resources:</p>
              <div className="ml-4">
                <p>
                  <span className="font-semibold">Memory: </span>
                  {formatBytes((compute?.memory || 0) * 1024 * 1024)}
                </p>
                <p>
                  <span className="font-semibold">CPU Cores: </span>{' '}
                  {compute?.vCpu}
                </p>
              </div>
            </div>
            <div>
              <p className="font-bold">Settings:</p>
              <div className="bg-surface-2 p-2 mt-2 mb-4 rounded-lg">
                <pre>
                  {JSON.stringify(
                    getDirtyValues(
                      dirtyFields as Record<string, unknown>,
                      form.getValues(),
                    ) as OdmSettings,
                    null,
                    4,
                  )}
                </pre>
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <Button onClick={() => setOnCreate(false)}>Back</Button>
            <Button type="submit">Submit</Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="gap-4 mb-4 grid">
            <TextController
              name={'droidmap.taskName' as Path<T>}
              defaultValue={'' as PathValue<T, Path<T>>}
              control={control}
              label="Task Name"
              errors={errors}
            />
            <SelectController
              name={'droidmap.assetSetId' as Path<T>}
              control={control}
              label="Select Asset Set"
              optionsNamed={[{ name: '', id: '' }, ...assetSets].map(
                (assetSet) => ({
                  name: assetSet.name,
                  value: assetSet.id,
                }),
              )}
              errors={errors}
            />
            <SelectController
              disabled
              name={'droidmap.taskType' as Path<T>}
              control={control}
              label="Select Task Type"
              defaultValue={TaskType.Photogrammetry as PathValue<T, Path<T>>}
              optionsNamed={Object.keys(TaskType).map((taskType) => ({
                name: pascalToSentence(taskType),
                value: TaskType[taskType as keyof typeof TaskType],
              }))}
              errors={errors}
            />
            <SelectController
              disabled
              name={'droidmap.outputAssetSetType' as Path<T>}
              control={control}
              label="Select Output Asset Set Type"
              defaultValue={AssetSetType.OdmOutputs as PathValue<T, Path<T>>}
              optionsNamed={Object.keys(AssetSetType).map((assetSetType) => ({
                name: pascalToSentence(assetSetType),
                value: AssetSetType[assetSetType as keyof typeof AssetSetType],
              }))}
              errors={errors}
            />
          </div>
          <FormSections<T> control={control} errors={errors} />
          <div className="flex justify-end">
            <Button
              disabled={!form.formState.isValid}
              onClick={() => setOnCreate(true)}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </form>
  )
}

export default OdmForm
