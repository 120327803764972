import { useQuery } from '@tanstack/react-query'

import { AssetSetType } from '@droidmap/asset-service-contract'

import Input from '../../components/Input'
import MultipleFileUpload from '../../components/MultiFileUpload'
import { useAuth } from '../../context/AuthContext'
import { useClient } from '../../context/ClientContext'
import { useAssetUpload } from '../../hooks/useAssetUpload'
import { useFormData } from '../../hooks/useFormData'
import { fetchProjects } from '../../lib/clientHelpers'

export default function AssetSetCreate() {
  const auth = useAuth()

  const [createState, handleCreateChange, resetCreateForm] = useFormData({
    assetName: '',
    projectId: '',
    assetType: '',
  })

  const { projectClient } = useClient()

  const { filesWithProgress, handleFileChange, handleUpload } = useAssetUpload({
    name: createState.assetName,
    projectId: createState.projectId,
    assetSetType: createState.assetType as AssetSetType,
  })

  const projectsQuery = useQuery({
    queryKey: ['projects', auth.activeOrganisation.id, projectClient],
    queryFn: () => fetchProjects(auth.activeOrganisation.id, projectClient),
  })

  const projects = projectsQuery.data || []

  return (
    <div className="space-y-2">
      <form onSubmit={resetCreateForm} className="space-y-6">
        <Input
          type="text"
          name="assetName"
          value={createState.assetName}
          onChange={handleCreateChange}
          placeholder="Asset Set Name"
        />
        <select
          name="projectId"
          value={createState.projectId}
          onChange={handleCreateChange}
          className="bg-input text-element-1 block w-full rounded-md border-0  py-1.5 shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">Select Project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>
              {project.name}
            </option>
          ))}
        </select>
        <select
          name="assetType"
          value={createState.assetType}
          onChange={handleCreateChange}
          className="bg-input text-element-1 block w-full rounded-md border-0  py-1.5 shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="">Select Asset Type</option>
          <option value={AssetSetType.DroneImages}>Drone Images</option>
          <option value={AssetSetType.OdmOutputs}>ODM Outputs</option>
        </select>
      </form>
      <MultipleFileUpload
        useFileUpload={() => ({
          filesWithProgress,
          handleFileChange,
          handleUpload,
        })}
      />
    </div>
  )
}
