import React, { useEffect, useState } from 'react'

import { useIntersectionObserver } from '../../hooks/useIntersectionObserver'
import { createThumbnail } from '../../workers/thumbnailWorker'

/**
 * Props for the ImageCard component.
 */
interface ImageCardProps {
  file?: File
  url?: string
  title?: string
  body?: React.ReactNode
  onClick?: () => void
}

/**
 * ImageCard component displays an image thumbnail and additional content.
 * The image can be provided as a file or a URL, and the thumbnail will be
 * generated or displayed based on the intersection observer's state.
 *
 * @param {ImageCardProps} props - The properties for the ImageCard component.
 * @returns {JSX.Element} The rendered ImageCard component.
 */
const ImageCard: React.FC<ImageCardProps> = ({
  file,
  url,
  title,
  body,
  onClick,
}) => {
  const { ref, isInView } = useIntersectionObserver('1000px 0px', 100)
  const [thumbnail, setThumbnail] = useState<string | null>(null)

  useEffect(() => {
    if (isInView) {
      if (file) {
        createThumbnail(file, 200, setThumbnail)
      } else if (url) {
        setThumbnail(url)
      }
    } else {
      setThumbnail(null)
    }
  }, [isInView, file, url])

  return (
    <div
      ref={ref}
      onClick={onClick}
      className="aspect-h-1 aspect-w-1 group relative overflow-hidden rounded-lg shadow-md focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
    >
      {isInView && thumbnail ? (
        <img
          src={thumbnail}
          alt="Uploaded file"
          className="pointer-events-none h-full w-full object-cover group-hover:opacity-80"
        />
      ) : (
        // Putting this into a separate components causes it to not render while loading
        // so leaving it as a simple img tag for now
        <img src="icon_white_square.png" alt="Loading thumbnail" />
      )}
      <div className="absolute inset-0 mt-auto h-1/3 flex-col bg-black bg-opacity-100 px-2 pt-1 opacity-75 transition-opacity">
        <div className="text-left text-sm font-bold text-white sm:text-base">
          {title}
        </div>
        {body}
      </div>
    </div>
  )
}

export default ImageCard
