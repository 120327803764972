import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ReactNode } from 'react'

import { cn } from '../../lib/utils'

interface AccordionItemProps {
  title: string
  children: ReactNode
  className?: string
}

const Accordion: React.FC<{
  items: AccordionItemProps[]
  className?: string
}> = ({ items, className }) => (
  <div className={cn('w-full', className)}>
    {items.map((item, index) => (
      <AccordionItem key={index} {...item} />
    ))}
  </div>
)

export const AccordionItem: React.FC<AccordionItemProps> = ({
  title,
  children,
  className,
}) => (
  <Disclosure>
    {({ open }) => (
      <>
        <DisclosureButton
          className={cn(
            'group flex items-center justify-between w-full border-b border-accent-base pb-2 text-left',
            className,
          )}
        >
          <span>{title}</span>
          <ChevronDownIcon
            className="size-5 fill-element-1/60 group-data-[hover]:fill-element-1/50 group-data-[open]:rotate-180 transition-transform duration-300"
            aria-hidden="true"
          />
        </DisclosureButton>
        <div className="py-2 overflow-y-hidden">
          <AnimatePresence>
            {open && (
              <DisclosurePanel
                static
                as={motion.div}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className=""
              >
                {children}
              </DisclosurePanel>
            )}
          </AnimatePresence>
        </div>
      </>
    )}
  </Disclosure>
)

export default Accordion
