import {
  Optional,
  PaginationControls,
  StorageSummary,
} from '@droidmap/shared/service-contract'

/* -------------------------------- AssetSet -------------------------------- */

export enum AssetSetType {
  DroneImages = 'drone_images',
  OdmOutputs = 'odm_outputs',
}

export type AssetSet = {
  /**
   * The unique id of the asset set. (ulid)
   *
   */
  id: string

  /**
   * The id of the project that this asset set belongs to. (ulid)
   */
  projectId: string

  /**
   * The user provided name of the asset set.
   */
  name: string

  /**
   * The type of asset set.
   */
  assetSetType: AssetSetType

  /**
   * The storage summary for the asset set.
   */
  storageSummary: StorageSummary

  /**
   * The pixel count for all the images in the asset set. We used this
   * to calculate the compute requirements for processing the images.
   */
  pixelCount?: number

  /**
   * The time the entity was created. This attribute is automatically
   * set by the dynamodb-onetable library.
   */
  created: Date

  /**
   * The user id of the user who created this entity.
   */
  createdBy: string

  /**
   * The last time this entity was modified. This attribute is automatically
   * set by the dynamodb-onetable library.
   */
  updated: Date

  /**
   * The user id of the user who last updated this entity.
   */
  updatedBy: string
}

/**
 * Inputs to create an asset set.
 */
export type CreateAssetSetInput = Omit<
  AssetSet,
  | 'id'
  | 'created'
  | 'updated'
  | 'createdBy'
  | 'updatedBy'
  | 'storageSummary'
  | 'pixelCount'
>

/**
 * The input required to get an AssetSet.
 */
export type GetAssetSetInput = {
  assetSetId: string
  presignedUrlType?: PresignedUrlType
}

/**
 * Inputs to update an asset set.
 *
 * NOTE: excluding projectId as this has a cascading effect on the s3
 * bucket keys/location. We can support moving assets sets between projects
 * in the future but for now we will keep it simple.
 */
export type UpdateAssetSetInput = Optional<
  Omit<
    AssetSet,
    | 'id'
    | 'projectId'
    | 'assets'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
    | 'storageSummary'
  >,
  'name' | 'assetSetType' | 'pixelCount'
> & {
  assetSetId: string
}

export type UpdateAssetSetStorageInput = Omit<
  AssetSet,
  | 'id'
  | 'projectId'
  | 'assets'
  | 'created'
  | 'updated'
  | 'createdBy'
  | 'updatedBy'
  | 'name'
  | 'assetSetType'
  | 'pixelCount'
> & {
  assetSetId: string
  storageSummary: StorageSummary
}

/**
 * The input required to delete an AssetSet.
 */
export type DeleteAssetSetInput = {
  assetSetId: string
}

/**
 * Inputs to list asset sets by project
 */
export type ListAssetSetsByProjectInput = {
  projectId: string
} & PaginationControls

/**
 * Models some aggregate information or statistics for AssetSets that are
 * used to store images like the AssetSetType.DroneImages type.
 */
export type ImageAssetSetStats = {
  /**
   * The total number of images in the set.
   */
  totalImages: number

  /**
   * The total number of pixels in all the images in the set.
   */
  totalPixelsInMegapixels: number

  /**
   * The total size of all the images in the set in bytes.
   */
  totalSizeInBytes: number

  /**
   * Are all the images in the set the same size/shape?
   */
  uniform: boolean

  /**
   * The dimensions of the images in the set.
   *
   * In the case the images are not uniform in size/shape this will be the
   * dimension of the largest image in the set.
   */
  imageDimensions: {
    width: number
    height: number
  }

  /**
   * The number of pixels in an individual image in this image set.
   * Expressed in megapixels.
   *
   * In the case the images are not uniform in size/shape this will be the
   * number of pixels in the largest image in the set.
   */
  imageMegapixel: number
}

/* ---------------------------------- Asset --------------------------------- */

export enum AssetType {
  Jpeg = 'jpeg',
  Tiff = 'tiff',
  Cog = 'cog', // Cloud Optimized GeoTIFF
  Json = 'json',
  Laz = 'laz',
  Raw = 'raw',
  Txt = 'txt',
  Geojson = 'geojson',
  Gpkg = 'gpkg',
  Pdf = 'pdf',
  Other = 'other',
}

export type AssetDimensions = {
  width: number
  height: number
}

export enum AssetStorageState {
  None = 'none',
  Standard = 'standard',
  Infrequent = 'infrequent',
  Glacier = 'glacier',
  DeepArchive = 'deep_archive',
}

export type Asset = {
  /**
   * The unique id of the asset. (ulid)
   */
  id: string

  /**
   * The id of the asset set that this asset belongs to. (ulid)
   */
  assetSetId: string

  /**
   * The filename of the asset.
   */
  name: string

  /**
   * The file type of the asset.
   */
  assetType: AssetType

  /**
   * The folder path this file live in relative to the base of the asset set.
   */
  folderPath?: string

  /**
   * The S3 bucket that this asset is stored in.
   */
  bucket: string

  /**
   * The S3 key prefix that this asset is stored under.
   */
  prefix: string

  /**
   * The S3 key that this asset is stored under.
   *
   * This is a concatenation of the prefix, folderPath and name.
   * We precompute this value so that we can use it as a sort key
   * and do lookups by bucket and key.
   */
  key: string

  /**
   * Has the asset upload to S3 been confirmed.
   */
  uploadConfirmed: boolean

  /**
   * Size of the file in bytes
   */
  size?: number

  /**
   * The dimensions (width/height) of the asset if it is an image.
   */
  dimensions?: AssetDimensions

  /**
   * This field contains the value of the ETag field returned in the
   * object created event. For files uploaded in one part this is the md5
   * checksum of the file. For files uploaded in multiple parts this is
   * the md5 checksum of the concatenated md5 checksums of each part.
   *
   * https://docs.aws.amazon.com/AmazonS3/latest/userguide/checking-object-integrity.html
   * https://stackoverflow.com/questions/1775816/how-to-get-the-md5sum-of-a-file-on-amazons-s3
   */
  checksum?: string

  /**
   * What storage tier the asset is currently stored in.
   */
  storageState: AssetStorageState

  /**
   * The time the entity was created. This attribute is automatically
   * set by the dynamodb-onetable library.
   */
  created: Date

  /**
   * The user id of the user who created this entity.
   */
  createdBy: string

  /**
   * The last time this entity was modified. This attribute is automatically
   * set by the dynamodb-onetable library.
   */
  updated: Date

  /**
   * The user id of the user who last updated this entity.
   */
  updatedBy: string
}

/**
 * A holder for presigned url information
 */
export type WithPresignedUrl = {
  presignedUrl: string
  presignedUrlExpiresAt: Date
}

export enum PresignedUrlType {
  GET = 'get',
  PUT = 'put',
}

export const presignedUrlTypeFromString = (
  presignedUrlTypeString: string,
): PresignedUrlType | undefined => {
  switch (presignedUrlTypeString) {
    case 'get':
      return PresignedUrlType.GET
    case 'put':
      return PresignedUrlType.PUT
    default:
      throw new Error(`Unknown presigned url type: ${presignedUrlTypeString}`)
  }
}

/**
 * For the client we want to return the asset with a presigned url
 * that allows either a get or put operation. We don't want to return
 * the bucket and prefix as that is an implementation detail.
 */
export type AssetWithPresignedUrl = Omit<
  Asset,
  'bucket' | 'prefix' | 'key' | 'storageState'
> &
  WithPresignedUrl
/**
 * Inputs to create an asset.
 */
export type CreateAssetInput = Omit<
  Asset,
  | 'id'
  | 'bucket'
  | 'prefix'
  | 'key'
  | 'uploadConfirmed'
  | 'storageState'
  | 'size'
  | 'checksum'
  | 'created'
  | 'updated'
  | 'createdBy'
  | 'updatedBy'
>

/**
 * The input required to get an Asset.
 */
export type GetAssetInput = {
  assetId: string
  presignedUrlType?: PresignedUrlType
}

/**
 * The input required when and Asset is uploaded to S3.
 */
export type UploadedAssetInput = {
  bucket: string
  key: string
  size: number
  checksum: string
}

/**
 * Inputs to update an asset.
 */
export type UpdateAssetInput = Optional<
  Omit<
    Asset,
    | 'id'
    | 'bucket'
    | 'prefix'
    | 'key'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
  >,
  | 'name'
  | 'assetSetId'
  | 'assetType'
  | 'folderPath'
  | 'uploadConfirmed'
  | 'size'
  | 'dimensions'
  | 'checksum'
  | 'storageState'
> & {
  assetId: string
}

/**
 * The subset of fields that can be updated by a client
 */
export type UpdateAssetExternalInput = Optional<
  Omit<
    Asset,
    | 'id'
    | 'bucket'
    | 'prefix'
    | 'key'
    | 'size'
    | 'assetSetId'
    | 'uploadConfirmed'
    | 'storageState'
    | 'dimensions'
    | 'checksum'
    | 'created'
    | 'updated'
    | 'createdBy'
    | 'updatedBy'
  >,
  'name' | 'assetType' | 'folderPath'
> & {
  assetId: string
}

/**
 * The input required to delete an Asset.
 */
export type DeleteAssetInput = {
  assetId: string
}

export type ListAssetsByAssetSetInput = {
  assetSetId: string
  presignedUrlType?: PresignedUrlType
} & PaginationControls

/**
 * AssetWithPresignedUrl type guard
 */
export const isAssetWithPresignedUrl = (
  asset: Asset | AssetWithPresignedUrl,
): asset is AssetWithPresignedUrl => {
  return (asset as AssetWithPresignedUrl).presignedUrl !== undefined
}
