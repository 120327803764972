import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import {
  AssetServiceClient,
  AssetSet as AssetSetType,
  AssetType,
  AssetWithPresignedUrl,
  PresignedUrlType,
} from '@droidmap/asset-service-contract'

// import SlideOver from '../components/SlideOver'
import ImageCard from '../../components/ImageCard'
import { useClient } from '../../context/ClientContext'
import { formatBytes } from '../../lib/utils'

const displayableTypes = new Set<AssetType>([AssetType.Jpeg])

function isDisplayable(assetType: AssetType): boolean {
  return displayableTypes.has(assetType)
}

async function fetchAssetSet(
  assetClient: AssetServiceClient,
  id: string,
): Promise<AssetSetType> {
  const response = await assetClient.getAssetSet({
    assetSetId: id,
    presignedUrlType: PresignedUrlType.GET,
  })
  if (!response) {
    throw new Error('Failed to fetch asset set')
  }
  return response
}

async function fetchAssets(
  assetClient: AssetServiceClient,
  assetSetId: string,
): Promise<AssetWithPresignedUrl[]> {
  const response = await assetClient.listAssetsByAssetSet({
    assetSetId,
    presignedUrlType: PresignedUrlType.GET,
  })
  if (!response) {
    throw new Error('Failed to fetch assets')
  }
  return response.items
}

export default function AssetSet() {
  const { id } = useParams()

  if (!id) {
    throw new Error('No asset set ID provided')
  }

  const { assetClient } = useClient()

  const assetSetQuery = useQuery({
    queryKey: ['assetSet', id, assetClient],
    queryFn: () => fetchAssetSet(assetClient, id),
  })
  const assetsQuery = useQuery({
    queryKey: ['assets', id, assetClient],
    queryFn: () => fetchAssets(assetClient, id),
  })

  if (assetSetQuery.isLoading || assetsQuery.isLoading) {
    return <div>Loading...</div>
  }
  if (assetSetQuery.isError || assetsQuery.isError) {
    return (
      <div>
        Error {assetSetQuery.error?.message || assetsQuery.error?.message}
      </div>
    )
  }

  return (
    <div>
      {/* <SlideOver
            information={{ Name: 'Brighton Beach', Assets: '10' }}
            title="Brighton Beach"
            subTitle="Drone Images"
            description="An asset set containing drone images of Brighton Beach."
            isOpen={viewItemOpen}
            onClose={() => setViewItemOpen(false)}
          /> */}
      <h1 className="text-element-1 text-2xl font-semibold">
        {assetSetQuery.data?.name} - {assetSetQuery.data?.id}
      </h1>

      <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:grid-cols-4 xl:gap-x-8 2xl:grid-cols-5">
        {assetsQuery.data?.map((asset, index) => (
          <ImageCard
            key={index}
            url={
              isDisplayable(asset.assetType)
                ? asset.presignedUrl
                : 'icon_white_square.png'
            }
            title={asset.name}
            body={
              <div>
                <p className="text-left text-xs font-medium text-white sm:text-sm">
                  {formatBytes(asset.size || 0)}
                </p>
                <p className="text-left text-xs font-medium text-white sm:text-sm">
                  {asset.dimensions?.width} x {asset.dimensions?.height} pixels
                </p>
              </div>
            }
          />
        ))}
      </div>
    </div>
  )
}
