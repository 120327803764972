import React, { ReactNode } from 'react'

interface CardProps {
  className?: string
  children: ReactNode
}

export const Card: React.FC<CardProps> = ({ className = '', children }) => {
  return (
    <div
      className={`bg-surface-2 shadow-md rounded-lg overflow-hidden mb-2 ${className}`}
    >
      {children}
    </div>
  )
}

interface CardHeaderProps {
  className?: string
  children: ReactNode
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  className = '',
  children,
}) => {
  return (
    <div
      className={`text-lg px-4 py-3 bg-surface-3 border-b border-accent-base ${className}`}
    >
      {children}
    </div>
  )
}

interface CardContentProps {
  className?: string
  children: ReactNode
}

export const CardContent: React.FC<CardContentProps> = ({
  className = '',
  children,
}) => {
  return <div className={`p-4 ${className}`}>{children}</div>
}
