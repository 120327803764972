import React from 'react'

interface PageHeaderProps {
  title: string
  children?: React.ReactNode
}
export default function PageHeader(props: PageHeaderProps) {
  return (
    <div className="bg-surface-2 rounded-md shadow-md">
      <div className="">
        <h2 className="text-element-1 p-4 text-2xl font-bold">{props.title}</h2>
      </div>
      {props.children}
    </div>
  )
}
