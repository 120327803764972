/**
 * A hook to get the bounding rectangle of a DOM element
 *
 * Source:
 *   https://gist.github.com/sujinleeme/c063b46bb78d97266bf37744d5f17223
 *
 * Stripped out the code related to updating the rect on window resize
 * as we don't want the map to recenter on window resize
 */
import { useCallback, useLayoutEffect, useRef, useState } from 'react'

type RectResult = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
} | null

const getRect = (element: HTMLElement | null): RectResult | null => {
  if (!element) return null
  return element.getBoundingClientRect()
}

export const useRect = (): [
  RectResult,
  React.MutableRefObject<HTMLDivElement | null>,
] => {
  const ref = useRef<HTMLDivElement | null>(null)
  const current = ref.current || null
  const [rect, setRect] = useState(getRect(current))

  const handleResize = useCallback(() => {
    if (!ref.current) return

    // Update client rect
    setRect(getRect(ref.current))
  }, [ref])

  useLayoutEffect(() => {
    const element = ref.current
    if (!element) return

    handleResize()
  }, [handleResize])

  return [rect, ref]
}
