import { useEffect, useRef, useState } from 'react'

import { debounce } from '../lib/utils'

/**
 * Custom hook to use Intersection Observer with debouncing.
 *
 * @param {string} rootMargin - The root margin for the observer.
 * @param {number} debounceDelay - The debounce delay in milliseconds.
 * @returns {{ ref: React.RefObject<HTMLDivElement>, isInView: boolean }} The ref and isInView state.
 */
export const useIntersectionObserver = (
  rootMargin: string,
  debounceDelay: number,
) => {
  const [isInView, setIsInView] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleIntersection = debounce(
      (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          setIsInView(entry.isIntersecting)
        })
      },
      debounceDelay,
    )

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin,
    })

    // Copy ref.current to a variable for later use
    const currentRef = ref.current

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [rootMargin, debounceDelay])

  return { ref, isInView }
}
