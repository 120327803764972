import { ColumnDef } from '@tanstack/react-table'

import { Project } from '@droidmap/project-service-contract'

import { Button } from '../../components/Button'

export const columns: ColumnDef<Project, keyof Project>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'type',
    header: 'Type',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'projectId',
    header: 'Project ID',
    cell: (info) => info.getValue(),
  },
  {
    accessorKey: 'created',
    header: 'Created At',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    accessorKey: 'updated',
    header: 'Updated At',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row, table }) => {
      const { onDelete, onView } = table.options.meta as {
        onDelete: (id: string) => void
        onView: (id: string) => void
      }
      return (
        <div className="flex space-x-2">
          <Button onClick={() => onView(row.original.id)} size="sm">
            View
          </Button>
          <Button
            onClick={() => onDelete(row.original.id)}
            size="sm"
            variant="destructive"
          >
            Delete
          </Button>
        </div>
      )
    },
  },
]
