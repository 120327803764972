import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AuthProvider } from './context/AuthContext'
import { ClientProvider } from './context/ClientContext'
import { ConfigProvider } from './context/ConfigContext'
import { ThemeProvider } from './context/ThemeContext'
import Router from './pages/router'

const queryClient = new QueryClient()

export function App() {
  return (
    <div className="bg-bkg text-text h-full">
      <ConfigProvider>
        <ThemeProvider>
          <AuthProvider>
            <ClientProvider>
              <QueryClientProvider client={queryClient}>
                <Router />
              </QueryClientProvider>
            </ClientProvider>
          </AuthProvider>
        </ThemeProvider>
      </ConfigProvider>
    </div>
  )
}

export default App
