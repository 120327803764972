import { z } from 'zod'

import { AssetSetType } from '@droidmap/asset-service-contract'
import { TaskType } from '@droidmap/project-service-contract'
import { OdmSettingsSchema } from '@droidmap/shared/odm-settings'

const droidmapFormSchema = z.object({
  droidmap: z.object({
    assetSetId: z
      .string({ message: 'Asset Set is required' })
      .ulid({ message: 'Asset Set is required' }),
    taskName: z
      .string()
      .min(1, { message: 'Must be 1 or more characters long' })
      .max(64, { message: 'Must be 64 or fewer characters long' }),
    taskType: z.nativeEnum(TaskType),
    outputAssetSetType: z.nativeEnum(AssetSetType),
  }),
})

export const taskSchema = z.intersection(droidmapFormSchema, OdmSettingsSchema)
export type TaskFormData = z.infer<typeof taskSchema>
