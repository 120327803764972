class WorkerPool {
  // Update if we want a static pool of workers, currently this pool just acts as a queue
  // private pool: Worker[] = []
  private taskQueue: (() => void)[] = []
  private maxWorkers: number
  private activeWorkers = 0

  constructor(maxWorkers: number) {
    this.maxWorkers = maxWorkers
  }

  private runTask() {
    if (this.taskQueue.length === 0 || this.activeWorkers >= this.maxWorkers) {
      return
    }

    const task = this.taskQueue.shift()
    if (task) {
      this.activeWorkers++
      task()
    }
  }

  private taskCompleted() {
    this.activeWorkers--
    this.runTask()
  }

  addTask<T>(
    script: string,
    payload: unknown,
    onComplete: (result: T) => void,
  ) {
    const task = () => {
      const worker = new Worker(new URL(script, import.meta.url), {
        type: 'module',
      })

      worker.onmessage = (event: MessageEvent) => {
        onComplete(event.data as T)
        worker.terminate()
        this.taskCompleted()
      }

      worker.postMessage(payload)
    }

    this.taskQueue.push(task)
    this.runTask()
  }
}

export default WorkerPool
