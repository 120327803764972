import { RefObject, useEffect } from 'react'

/**
 * Custom hook that applies a specified CSS class to an element's scrollbar based on its content height.
 * @param ref - Reference to the element to observe.
 * @param style - CSS class to add or remove from the element's class list.
 */
const useScrollbarStyle = (ref: RefObject<HTMLElement>, style: string) => {
  useEffect(() => {
    const element = ref.current

    const addScrollbarStyle = () => {
      if (element && element.scrollHeight > element.clientHeight) {
        element.classList.add(style)
      } else if (element) {
        element.classList.remove(style)
      }
    }

    const observer = new ResizeObserver(addScrollbarStyle)
    if (element) {
      observer.observe(element)
    }

    addScrollbarStyle() // Run on initial load

    window.addEventListener('resize', addScrollbarStyle)

    return () => {
      window.removeEventListener('resize', addScrollbarStyle)
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [ref, style])
}

export default useScrollbarStyle
