interface ErrorMessageProps {
  content: string
}

export default function ErrorMessage(props: ErrorMessageProps) {
  return (
    <div role="alert">
      <div className="rounded border border-red-400 bg-red-100 px-2 py-2 text-xs text-red-700">
        {props.content}
      </div>
    </div>
  )
}
