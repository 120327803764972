import React from 'react'

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    return (
      <div>
        <label
          htmlFor={props.name ?? props.id}
          className="text-element-1 block text-sm font-medium leading-6"
        >
          {props.label}
        </label>
        <div className="mt-2">
          <select
            {...props}
            ref={ref}
            className="bg-input text-element-1 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
    )
  },
)

Select.displayName = 'Select'

export default Select
