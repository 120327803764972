import React, { useReducer } from 'react'

import { formReducer } from '../reducers/formReducer'

export function useFormData<T>(
  initialState: T,
): [
  T,
  (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void,
  () => void,
] {
  const [state, dispatch] = useReducer(formReducer, initialState)

  const handleFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    dispatch({ [e.target.name]: e.target.value })
  }

  const resetForm = () => {
    dispatch(initialState)
  }

  return [state as T, handleFormChange, resetForm]
}
