import {
  ServiceClient,
  ServiceClientContext,
} from '@droidmap/shared/service-contract'

import { CogInfo, GetCogInfoInput } from '../models/tile'

export class TileServiceClient extends ServiceClient {
  public static readonly SERVICE_NAME = 'TileService'

  constructor(input: ServiceClientContext) {
    super({ ...input, serviceName: TileServiceClient.SERVICE_NAME })
  }

  getCogInfo = async (getCogInfoInput: GetCogInfoInput): Promise<CogInfo> => {
    const { assetId } = getCogInfoInput

    const cogInfo = await this.callApiRouteWithToken<GetCogInfoInput, CogInfo>({
      method: 'GET',
      path: `/cog/assets/${assetId}/info`,
    })

    return cogInfo
  }
}
