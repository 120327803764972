import { Step } from '../../components/Flow'
import { Status } from '../../components/Status'

interface DataPoint {
  timestamp: number
  value: number
}

// Helper function to generate a random value within a range
const randomValue = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// Generate 24 hours of data points, one for every 5 minutes
const generateMemoryUsageData = (): DataPoint[] => {
  const data: DataPoint[] = []
  const now = new Date()
  now.setHours(0, 0, 0, 0) // Start from midnight

  for (let i = 0; i < 288; i++) {
    // 24 hours * 12 (5-minute intervals)
    const timestamp = now.getTime() + i * 5 * 60 * 1000 // Add 5 minutes for each iteration

    // Simulate a daily pattern with higher usage during work hours
    const hourOfDay = (i * 5) / 60
    let baseValue: number

    if (hourOfDay >= 9 && hourOfDay < 17) {
      // Higher usage during work hours (9 AM to 5 PM)
      baseValue = randomValue(4000, 6000)
    } else if (
      (hourOfDay >= 7 && hourOfDay < 9) ||
      (hourOfDay >= 17 && hourOfDay < 19)
    ) {
      // Moderate usage during commute hours
      baseValue = randomValue(3000, 5000)
    } else {
      // Lower usage during night hours
      baseValue = randomValue(2000, 4000)
    }

    // Add some random fluctuation
    const value = baseValue + randomValue(-500, 500)

    data.push({ timestamp, value })
  }

  return data
}

export const memoryUsageData = generateMemoryUsageData()

export const taskLogs = [
  {
    id: '1',
    name: 'Aerial Site Reconstruction',
    status: Status.Success,
    logs: [
      {
        id: '1',
        message: 'Drone imagery received',
        timestamp: '2023-04-10 10:00:00',
      },
      {
        id: '2',
        message: 'Initial image alignment started',
        timestamp: '2023-04-10 10:15:00',
      },
      {
        id: '3',
        message: 'Sparse point cloud generated',
        timestamp: '2023-04-10 11:30:00',
      },
      {
        id: '4',
        message: 'Dense point cloud processing initiated',
        timestamp: '2023-04-10 13:00:00',
      },
      {
        id: '5',
        message: 'Mesh generation started',
        timestamp: '2023-04-10 15:45:00',
      },
      {
        id: '6',
        message: 'Texture mapping in progress',
        timestamp: '2023-04-10 17:30:00',
      },
      {
        id: '7',
        message: '3D model export completed',
        timestamp: '2023-04-10 18:45:00',
      },
      {
        id: '8',
        message: 'Quality check passed',
        timestamp: '2023-04-10 19:15:00',
      },
      {
        id: '9',
        message: 'Project delivered to client',
        timestamp: '2023-04-10 19:30:00',
      },
      {
        id: '10',
        message: 'Project complete',
        timestamp: '2023-04-10 19:30:01',
      },
    ],
    runtime: 5432,
  },
  {
    id: '2',
    name: 'Product 360° View Creation',
    status: Status.Running,
    logs: [
      {
        id: '10',
        message: 'Product received for imaging',
        timestamp: '2023-04-11 09:00:00',
      },
      {
        id: '11',
        message: 'Studio setup completed',
        timestamp: '2023-04-11 09:30:00',
      },
      {
        id: '12',
        message: 'Photography session started',
        timestamp: '2023-04-11 10:00:00',
      },
      {
        id: '13',
        message: '180 images captured',
        timestamp: '2023-04-11 11:45:00',
      },
      {
        id: '14',
        message: 'Image processing and color correction begun',
        timestamp: '2023-04-11 13:00:00',
      },
      {
        id: '15',
        message: '50% of images processed',
        timestamp: '2023-04-11 15:30:00',
      },
      {
        id: '16',
        message: 'Awaiting final quality check',
        timestamp: '2023-04-11 17:00:00',
      },
    ],
    runtime: 28848,
  },
  {
    id: '3',
    name: 'LiDAR Point Cloud Processing',
    status: Status.Pending,
    logs: [
      {
        id: '17',
        message: 'LiDAR data received from field team',
        timestamp: '2023-04-12 14:00:00',
      },
      {
        id: '18',
        message: 'Initial data validation completed',
        timestamp: '2023-04-12 14:30:00',
      },
      {
        id: '19',
        message: 'Awaiting processing resources',
        timestamp: '2023-04-12 15:00:00',
      },
    ],
    runtime: 0,
  },
  {
    id: '4',
    name: 'Thermal Imagery Analysis',
    status: Status.Error,
    logs: [
      {
        id: '20',
        message: 'Thermal imagery dataset loaded',
        timestamp: '2023-04-13 09:00:00',
      },
      {
        id: '21',
        message: 'Calibration process started',
        timestamp: '2023-04-13 09:15:00',
      },
      {
        id: '22',
        message: 'Temperature range normalization applied',
        timestamp: '2023-04-13 10:00:00',
      },
      {
        id: '23',
        message: 'Hotspot detection algorithm running',
        timestamp: '2023-04-13 11:30:00',
      },
      {
        id: '24',
        message: 'False color mapping generated',
        timestamp: '2023-04-13 13:00:00',
      },
      {
        id: '25',
        message: 'Anomaly report compilation started',
        timestamp: '2023-04-13 14:30:00',
      },
      {
        id: '26',
        message: 'GIS integration completed',
        timestamp: '2023-04-13 16:00:00',
      },
      {
        id: '27',
        message: 'Final report generated',
        timestamp: '2023-04-13 17:15:00',
      },
      {
        id: '28',
        message: 'Results presented to engineering team',
        timestamp: '2023-04-13 18:00:00',
      },
    ],
    runtime: 51,
  },
  {
    id: '5',
    name: 'Multispectral Crop Analysis',
    status: Status.Cancelled,
    logs: [
      {
        id: '29',
        message: 'Multispectral imagery received',
        timestamp: '2023-04-14 10:00:00',
      },
      {
        id: '30',
        message: 'Initial band alignment performed',
        timestamp: '2023-04-14 10:30:00',
      },
      {
        id: '31',
        message: 'Atmospheric correction applied',
        timestamp: '2023-04-14 11:15:00',
      },
      {
        id: '32',
        message: 'NDVI calculation started',
        timestamp: '2023-04-14 12:00:00',
      },
      {
        id: '33',
        message: 'Client requested project cancellation',
        timestamp: '2023-04-14 13:30:00',
      },
      {
        id: '34',
        message: 'Data archival process initiated',
        timestamp: '2023-04-14 14:00:00',
      },
      {
        id: '35',
        message: 'Project resources reallocated',
        timestamp: '2023-04-14 14:30:00',
      },
    ],
    runtime: 350,
  },
]

export const cpuData = [
  { timestamp: 1633027200000, value: 25 },
  { timestamp: 1633027260000, value: 30 },
  { timestamp: 1633027320000, value: 28 },
  { timestamp: 1633027380000, value: 35 },
  { timestamp: 1633027440000, value: 40 },
  { timestamp: 1633027500000, value: 45 },
  { timestamp: 1633027560000, value: 50 },
  { timestamp: 1633027620000, value: 55 },
  { timestamp: 1633027680000, value: 60 },
  { timestamp: 1633027740000, value: 65 },
]

export const steps: Step[] = [
  {
    id: '1',
    name: 'Start',
    status: Status.Success,
    startTime: 1729466345509,
    endTime: 1729466346509,
    dependencies: [],
  },
  {
    id: '2',
    name: 'Collect Data',
    status: Status.Success,
    startTime: 1729466346000,
    endTime: 1729466846000,
    dependencies: ['1'],
  },
  {
    id: '5',
    name: 'Process C',
    status: Status.Pending,
    startTime: 1729466347000,
    endTime: 1729466347000,
    dependencies: ['2'],
  },
  {
    id: '3',
    name: 'Process A',
    status: Status.Running,
    startTime: 1729466348000,
    endTime: 1729466378000,
    dependencies: ['2'],
  },
  {
    id: '4',
    name: 'Process B',
    status: Status.Running,
    startTime: 1729466449000,
    endTime: 1729466349000,
    dependencies: ['2'],
  },
  {
    id: '6',
    name: 'Analyze Results',
    status: Status.Cancelled,
    startTime: 1729468350000,
    endTime: 1729466350000,
    dependencies: ['3', '4', '5'],
  },
  {
    id: '7',
    name: 'Generate Report',
    status: Status.Pending,
    startTime: 1729466351000,
    endTime: 1729466351000,
    dependencies: ['6'],
  },
  {
    id: '8',
    name: 'Review',
    status: Status.Pending,
    startTime: 1729466352000,
    endTime: 1729466352000,
    dependencies: ['7'],
  },
  {
    id: '9',
    name: 'Finalize',
    status: Status.Error,
    startTime: 1729466353000,
    endTime: 1729466353000,
    dependencies: ['8', '12'],
  },
  {
    id: '10',
    name: 'Floating',
    status: Status.Pending,
    startTime: 1729466354000,
    endTime: 1729466354000,
    dependencies: ['4'],
  },
  {
    id: '12',
    name: 'Floating',
    status: Status.Pending,
    startTime: 1729466355000,
    endTime: 1729466355000,
    dependencies: ['10', '3'],
  },
  // {
  //   id: '13',
  //   name: 'Floating',
  //   status: Status.Pending,
  //   startTime: 1729466355000,
  //   endTime: 1729466355000,
  //   dependencies: ['9'],
  // },
]
