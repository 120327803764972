import { QueryParameterBag } from '@smithy/types'

export const DEFAULT_PAGE_SIZE = 50

export type Paged<T> = {
  items: T[]
  next?: object
  prev?: object
}

export class PagedUtils {
  static empty<T>(): Paged<T> {
    return {
      items: [],
    }
  }
}

export type PaginationControls = {
  next?: object
  prev?: object
  pageSize?: number
}

export const calculatePageStartMarker = (next?: object, prev?: object) => {
  let pageStartMarker = {}
  if (next && prev) {
    throw new Error(
      'Cannot specify both next and prev when requesting paged results',
    )
  } else if (next) {
    pageStartMarker = {
      next,
    }
  } else if (prev) {
    pageStartMarker = {
      prev,
    }
  }

  return pageStartMarker
}

export const paginationControlsFromQueryParams = (params?: {
  [name: string]: string | undefined
}): PaginationControls => {
  let next: object | undefined
  let prev: object | undefined
  let pageSize: number | undefined

  if (params) {
    const {
      next: nextString,
      prev: prevString,
      pageSize: pageSizeString,
    } = params

    next = nextString ? JSON.parse(nextString) : undefined
    prev = prevString ? JSON.parse(prevString) : undefined
    pageSize = pageSizeString ? parseInt(pageSizeString) : undefined
  }

  return {
    next,
    prev,
    pageSize,
  }
}

export const queryParameterBagFromPaginationControls = (
  controls: PaginationControls,
): QueryParameterBag => {
  const { next, prev, pageSize } = controls

  const bag: {
    [x: string]: string | string[] | undefined
  } = {
    next: next ? JSON.stringify(next) : undefined,
    prev: prev ? JSON.stringify(prev) : undefined,
    pageSize: pageSize ? pageSize.toString() : undefined,
  }

  // Remove any undefined values
  Object.keys(bag).forEach((key) => bag[key] === undefined && delete bag[key])

  return bag as QueryParameterBag
}
