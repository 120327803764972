import { QueryParameterBag } from '@smithy/types'

import {
  DroidMapMetadata,
  Paged,
  ServiceClient,
  ServiceClientContext,
  queryParameterBagFromPaginationControls,
} from '@droidmap/shared/service-contract'

import {
  CreateProjectInput,
  DeleteProjectInput,
  GetProjectInput,
  ListProjectsByOrganisationInput,
  Project,
  UpdateProjectInput,
} from '../models/project'
import {
  CreatePhotogrammetryTaskInput,
  CreateTaskInput,
  DeleteTaskInput,
  GetTaskInput,
  ListTasksByProjectInput,
  Task,
  UpdateTaskInput,
} from '../models/task'

export class ProjectServiceClient extends ServiceClient {
  public static readonly SERVICE_NAME = 'ProjectService'

  constructor(input: ServiceClientContext) {
    super({ ...input, serviceName: ProjectServiceClient.SERVICE_NAME })
  }

  /* --------------------------------- Project -------------------------------- */

  createProject = async (
    createProjectInput: CreateProjectInput,
    metadata?: DroidMapMetadata,
  ): Promise<Project> => {
    const project = await this.callApiRouteWithToken<
      CreateProjectInput,
      Project
    >({
      method: 'POST',
      path: '/projects',
      body: createProjectInput,
      metadata,
    })

    return project
  }

  getProject = async (
    input: GetProjectInput,
    metadata?: DroidMapMetadata,
  ): Promise<Project> => {
    const project = await this.callApiRouteWithToken<string, Project>({
      method: 'GET',
      path: `/projects/${input.projectId}`,
      metadata,
    })

    return project
  }

  updateProject = async (
    input: UpdateProjectInput,
    metadata?: DroidMapMetadata,
  ): Promise<Project> => {
    const project = await this.callApiRouteWithToken<Partial<Project>, Project>(
      {
        method: 'PATCH',
        path: `/projects/${input.projectId}`,
        body: {
          name: input.name,
        },
        metadata,
      },
    )

    return project
  }

  deleteProject = async (
    input: DeleteProjectInput,
    metadata?: DroidMapMetadata,
  ): Promise<Project> => {
    const project = await this.callApiRouteWithToken<string, Project>({
      method: 'DELETE',
      path: `/projects/${input.projectId}`,
      metadata,
    })

    return project
  }

  listProjectsByOrganisation = async (
    input: ListProjectsByOrganisationInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<Project>> => {
    const { orgId, next, prev, pageSize } = input

    const queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    const projects = await this.callApiRouteWithToken<string, Paged<Project>>({
      method: 'GET',
      path: `/organisations/${orgId}/projects`,
      queryParameters,
      metadata,
    })

    return projects
  }

  /* ---------------------------------- Task ---------------------------------- */

  createTask = async (
    input: CreateTaskInput | CreatePhotogrammetryTaskInput,
    metadata?: DroidMapMetadata,
  ): Promise<Task> => {
    return await this.callApiRouteWithToken<
      CreateTaskInput | CreatePhotogrammetryTaskInput,
      Task
    >({
      method: 'POST',
      path: `/tasks`,
      body: input,
      metadata,
    })
  }

  getTask = async (
    input: GetTaskInput,
    metadata?: DroidMapMetadata,
  ): Promise<Task> => {
    return await this.callApiRouteWithToken<string, Task>({
      method: 'GET',
      path: `/tasks/${input.taskId}`,
      metadata,
    })
  }

  updateTask = async (
    input: UpdateTaskInput,
    metadata?: DroidMapMetadata,
  ): Promise<Task> => {
    const task = await this.callApiRouteWithToken<Partial<Task>, Task>({
      method: 'PATCH',
      path: `/tasks/${input.taskId}`,
      body: {
        name: input.name,
      },
      metadata,
    })

    return task
  }

  deleteTask = async (
    input: DeleteTaskInput,
    metadata?: DroidMapMetadata,
  ): Promise<Task> => {
    return await this.callApiRouteWithToken<string, Task>({
      method: 'DELETE',
      path: `/tasks/${input.taskId}`,
      metadata,
    })
  }

  listTasksByProject = async (
    input: ListTasksByProjectInput,
    metadata?: DroidMapMetadata,
  ): Promise<Task[]> => {
    return await this.callApiRouteWithToken<string, Task[]>({
      method: 'GET',
      path: `/projects/${input.projectId}/tasks`,
      metadata,
    })
  }
}
