import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { FC, Fragment } from 'react'

interface ModalProps {
  title?: string
  description?: string
  icon?: React.ReactNode
  isOpen: boolean
  onClose: () => void
  children?: React.ReactNode
}

const Modal: FC<ModalProps> = ({
  title,
  description,
  icon,
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) {
    return null
  }

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto lg:pl-72">
          <div className="flex min-h-full items-end items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className="bg-surface-1 relative w-full transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-6xl sm:p-6"
                autoFocus={false}
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="bg-surface-2 text-element-1 hover:bg-surface-3 rounded-md"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  {icon && (
                    <div className="bg-surface-2 mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:mr-4 sm:h-10 sm:w-10">
                      <div className="text-element-1 h-6 w-6">{icon}</div>
                    </div>
                  )}
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    {title && (
                      <DialogTitle
                        as="h3"
                        className="text-element-1 text-base font-semibold leading-6"
                      >
                        {title}
                      </DialogTitle>
                    )}
                    {description && (
                      <Description className="text-element-1">
                        {description}
                      </Description>
                    )}
                  </div>
                </div>
                <div className="pt-6">{children}</div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
