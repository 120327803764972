/**
 * We are currently using ArcGIS as the source for our basemap tiles.
 */
export enum BasemapStyle {
  Outdoor = 'arcgis/outdoor',
  Community = 'arcgis/community',
  Navigation = 'arcgis/navigation',
  Streets = 'arcgis/streets',
  StreetsRelief = 'arcgis/streets-relief',
  Imagery = 'arcgis/imagery',
  Oceans = 'arcgis/oceans',
  Topographic = 'arcgis/topographic',
  LightGray = 'arcgis/light-gray',
  DarkGray = 'arcgis/dark-gray',
  HumanGeography = 'arcgis/human-geography',
  ChartedTerritory = 'arcgis/charted-territory',
  Nova = 'arcgis/nova',
  OSMStandard = 'osm/standard',
  OSMNavigation = 'osm/navigation',
  OSMStreets = 'osm/streets',
  OSMBlueprint = 'osm/blueprint',
}

export const createBaseMapStyle = (basemapStyle: BasemapStyle): string => {
  // ArcGIS Developer Account API key - Created under James's account
  // TODO - move this behind an api
  const apiKey =
    'AAPK26ded56725d1426bbd01ff1df7f8feebWzptmfUMuE7y8RlQYAOsf36jFr34d6rUcUY-dMgVop0Os_K5MyheHMfbMxfcm3zU'

  return `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/${basemapStyle}?token=${apiKey}`
}
