import { ErrorMessage } from '@hookform/error-message'
import React from 'react'
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldPathValue,
  FieldValues,
} from 'react-hook-form'

import Checkbox from '../Checkbox'
import Input from '../Input'
import Select from '../Select'

/**
 * Base props for all form controllers
 */
interface BaseControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> {
  name: TName
  control: Control<TFieldValues>
  label: string
  defaultValue?: FieldPathValue<TFieldValues, TName>
  errors?: FieldErrors<TFieldValues>
  disabled?: boolean
}

/**
 * Renders a form field with error handling
 */
function FormField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  errors,
  children,
}: {
  name: TName
  errors?: FieldErrors<TFieldValues>
  children: React.ReactNode
}) {
  return (
    <div>
      {children}
      {errors && (
        <ErrorMessage
          errors={errors}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name={name as any}
          render={({ message }) => (
            <p className="mt-1 text-sm font-bold text-red-600">{message}</p>
          )}
        />
      )}
    </div>
  )
}

/**
 * Text input controller
 */
export function TextController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  label,
  errors,
  disabled,
}: BaseControllerProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormField name={name} errors={errors}>
          <Input
            {...field}
            type="text"
            className="mt-1 block w-full"
            label={label}
            id={name}
            disabled={disabled}
          />
        </FormField>
      )}
    />
  )
}

/**
 * Checkbox controller
 */
export function CheckboxController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  label,
  errors,
  disabled,
}: BaseControllerProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormField name={name} errors={errors}>
          <Checkbox
            {...field}
            className="mt-1 block"
            checked={field.value}
            label={label}
            id={name}
            disabled={disabled}
          />
        </FormField>
      )}
    />
  )
}

/**
 * Select controller props
 */
interface SelectControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends BaseControllerProps<TFieldValues, TName> {
  options?: string[]
  optionsNamed?: Array<{ name: string; value: string }>
}

/**
 * Select controller
 */
export function SelectController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  label,
  errors,
  options,
  optionsNamed,
  disabled,
}: SelectControllerProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormField name={name} errors={errors}>
          <Select
            {...field}
            label={label}
            id={name}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            disabled={disabled}
          >
            {options?.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
            {optionsNamed?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.name}
              </option>
            ))}
          </Select>
        </FormField>
      )}
    />
  )
}

/**
 * Number controller props
 */
interface NumberControllerProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
> extends BaseControllerProps<TFieldValues, TName> {
  step?: string
  min?: string
  max?: string
}

/**
 * Number input controller
 */
export function NumberController<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  name,
  control,
  defaultValue,
  label,
  errors,
  step,
  min,
  max,
  disabled,
}: NumberControllerProps<TFieldValues, TName>) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormField name={name} errors={errors}>
          <Input
            {...field}
            type="number"
            step={step}
            onChange={(e) =>
              field.onChange(Number(e.target.value) || e.target.value)
            }
            min={min}
            max={max}
            id={name}
            label={label}
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
            disabled={disabled}
          />
        </FormField>
      )}
    />
  )
}
