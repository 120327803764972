import Analytics from './Analytics'
import AssetSet from './AssetSet'
import AssetSetCreate from './AssetSetCreate'
import AssetSets from './AssetSets'
import ConfirmSignUp from './ConfirmSignUp'
import Dashboard from './Dashboard'
import Datasets from './Datasets'
import ForgotPassword from './ForgotPassword'
import Groups from './Groups'
import Login from './Login'
import Maps from './Maps'
import Organisations from './Organisations'
import Project from './Project'
import Projects from './Projects'
import Settings from './Settings'
import Signup from './SignUp'
import Tasks from './Tasks'
import UserProfile from './UserProfile'

interface PageData {
  title: string
  path: string
  element: JSX.Element
  public: boolean
}

const pagesData: PageData[] = [
  /* ------------------------------ Public Routes ----------------------------- */
  {
    title: 'Login',
    path: '/login',
    element: <Login />,
    public: true,
  },
  {
    title: 'Sign Up',
    path: '/signup',
    element: <Signup />,
    public: true,
  },
  {
    title: 'Confirm Sign Up',
    path: '/confirm-signup',
    element: <ConfirmSignUp />,
    public: true,
  },
  {
    title: 'Forgot Password',
    path: '/forgot-password',
    element: <ForgotPassword />,
    public: true,
  },
  /* ----------------------------- Private Routes ----------------------------- */
  {
    title: 'Dashboard',
    path: '/',
    element: <Dashboard />,
    public: false,
  },
  {
    title: 'Profile',
    path: '/profile',
    element: <UserProfile />,
    public: false,
  },
  {
    title: 'Organisations',
    path: '/organisations',
    element: <Organisations />,
    public: false,
  },
  {
    title: 'Groups',
    path: '/groups',
    element: <Groups />,
    public: false,
  },
  {
    title: 'Projects',
    path: '/projects',
    element: <Projects />,
    public: false,
  },
  {
    title: 'Asset Set Detail',
    path: '/asset-sets/:id',
    element: <AssetSet />,
    public: false,
  },
  {
    title: 'Asset Sets',
    path: '/asset-sets',
    element: <AssetSets />,
    public: false,
  },
  {
    title: 'Tasks',
    path: '/tasks',
    element: <Tasks />,
    public: false,
  },
  {
    title: 'Datasets',
    path: '/datasets',
    element: <Datasets />,
    public: false,
  },
  {
    title: 'Maps',
    path: '/maps',
    element: <Maps />,
    public: false,
  },
  {
    title: 'Settings',
    path: '/settings',
    element: <Settings />,
    public: false,
  },
  {
    title: 'Analytics',
    path: '/analytics',
    element: <Analytics />,
    public: false,
  },
  {
    title: 'Create Asset Set',
    path: '/asset-sets/create',
    element: <AssetSetCreate />,
    public: false,
  },
  {
    title: 'Project',
    path: '/projects/:id',
    element: <Project />,
    public: false,
  },
]

export default pagesData
