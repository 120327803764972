import { QueryParameterBag } from '@smithy/types'

import {
  DroidMapMetadata,
  Paged,
  ServiceClient,
  ServiceClientContext,
  queryParameterBagFromPaginationControls,
} from '@droidmap/shared/service-contract'

import {
  CreateGroupInput,
  CreateGroupUserInput,
  CreateOrganisationInput,
  CreateOrganisationUserInput,
  DeleteGroupInput,
  DeleteGroupUserInput,
  DeleteOrganisationInput,
  DeleteOrganisationUserInput,
  GetOrganisationInput,
  GetOrganisationUserInput,
  Group,
  GroupUser,
  ListGroupsByOrganisationInput,
  ListGroupsByOrganisationUserInput,
  ListOrganisationUsersByGroupInput,
  ListOrganisationUsersByOrganisationInput,
  ListOrganisationsByUserInput,
  Organisation,
  OrganisationUser,
  UpdateOrganisationInput,
  UpdateOrganisationUserInput,
} from '../models/organisation-models'

export class OrganisationServiceClient extends ServiceClient {
  public static readonly SERVICE_NAME = 'OrganisationService'

  constructor(input: ServiceClientContext) {
    super({ ...input, serviceName: OrganisationServiceClient.SERVICE_NAME })
  }

  /* ------------------------------ Organisation ------------------------------ */

  createOrganisation = async (
    createOrganisationInput: CreateOrganisationInput,
    metadata?: DroidMapMetadata,
  ): Promise<Organisation> => {
    const organisation = await this.callApiRouteWithToken<
      CreateOrganisationInput,
      Organisation
    >({
      method: 'POST',
      path: '/organisations',
      body: createOrganisationInput,
      metadata,
    })

    if (organisation === undefined) {
      throw new Error(
        'Organisation was not created with input: ' +
          JSON.stringify(createOrganisationInput),
      )
    }

    return organisation
  }

  getOrganisation = async (
    getOrganisationInput: GetOrganisationInput,
    metadata?: DroidMapMetadata,
  ): Promise<Organisation> => {
    const organisation = await this.callApiRouteWithToken<
      GetOrganisationInput,
      Organisation
    >({
      method: 'GET',
      path: `/organisations/${getOrganisationInput.orgId}`,
      metadata,
    })

    return organisation
  }

  updateOrganisation = async (
    updateOrganisationInput: UpdateOrganisationInput,
    metadata?: DroidMapMetadata,
  ): Promise<Organisation> => {
    const organisation = await this.callApiRouteWithToken<
      UpdateOrganisationInput,
      Organisation
    >({
      method: 'PATCH',
      path: `/organisations/${updateOrganisationInput.orgId}`,
      body: updateOrganisationInput,
      metadata,
    })

    return organisation
  }

  deleteOrganisation = async (
    deleteOrganisationInput: DeleteOrganisationInput,
    metadata?: DroidMapMetadata,
  ): Promise<Organisation> => {
    const group = await this.callApiRouteWithToken<
      DeleteOrganisationInput,
      Organisation
    >({
      method: 'DELETE',
      path: `/organisations/${deleteOrganisationInput.orgId}`,
      metadata,
    })

    return group
  }

  listOrganisationsByUser = async (
    input: ListOrganisationsByUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<Organisation>> => {
    const { userId, next, prev, pageSize } = input

    const queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    const organisations = await this.callApiRouteWithToken<
      ListOrganisationsByUserInput,
      Paged<Organisation>
    >({
      method: 'GET',
      path: `/users/${userId}/organisations`,
      queryParameters,
      metadata,
    })

    // Return the organisations and wrap the next and prev page tokens
    // from the organisation users response
    return {
      items: organisations.items,
      next: organisations.next,
      prev: organisations.prev,
    }
  }

  /* ---------------------------- OrganisationUser ---------------------------- */

  createOrganisationUser = async (
    createOrganisationUserInput: CreateOrganisationUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<OrganisationUser> => {
    const organisationUser = await this.callApiRouteWithToken<
      CreateOrganisationUserInput,
      OrganisationUser
    >({
      method: 'POST',
      path: `/organisations/${createOrganisationUserInput.orgId}/organisationUsers`,
      body: createOrganisationUserInput,
      metadata,
    })

    if (organisationUser === undefined) {
      throw new Error(
        'Organisation user was not created with input: ' +
          JSON.stringify(createOrganisationUserInput),
      )
    }

    return organisationUser
  }

  getOrganisationUser = async (
    getOrganisationUserInput: GetOrganisationUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<OrganisationUser> => {
    const orgUser = await this.callApiRouteWithToken<
      GetOrganisationUserInput,
      OrganisationUser
    >({
      method: 'GET',
      path: `/organisations/${getOrganisationUserInput.orgId}/organisationUsers/${getOrganisationUserInput.userId}`,
      metadata,
    })

    return orgUser
  }

  updateOrganisationUser = async (
    updateOrganisationUserInput: UpdateOrganisationUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<OrganisationUser> => {
    const organisationUser = await this.callApiRouteWithToken<
      UpdateOrganisationUserInput,
      OrganisationUser
    >({
      method: 'PATCH',
      path: `/organisations/${updateOrganisationUserInput.orgId}/organisationUsers/${updateOrganisationUserInput.userId}`,
      body: updateOrganisationUserInput,
      metadata,
    })

    return organisationUser
  }

  deleteOrganisationUser = async (
    deleteOrganisationUserInput: DeleteOrganisationUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<OrganisationUser> => {
    const organisationUser = await this.callApiRouteWithToken<
      DeleteOrganisationUserInput,
      OrganisationUser
    >({
      method: 'DELETE',
      path: `/organisations/${deleteOrganisationUserInput.orgId}/organisationUsers/${deleteOrganisationUserInput.userId}`,
      metadata,
    })

    return organisationUser
  }

  listOrganisationUsersByOrganisation = async (
    input: ListOrganisationUsersByOrganisationInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<OrganisationUser>> => {
    const { orgId, next, prev, pageSize } = input

    const queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    const users = await this.callApiRouteWithToken<
      ListOrganisationUsersByOrganisationInput,
      Paged<OrganisationUser>
    >({
      method: 'GET',
      path: `/organisations/${orgId}/organisationUsers`,
      queryParameters,
      metadata,
    })

    return users
  }

  listOrganisationUsersByGroup = async (
    input: ListOrganisationUsersByGroupInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<OrganisationUser>> => {
    const { groupId, next, prev, pageSize } = input

    const queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    const users = await this.callApiRouteWithToken<
      ListOrganisationUsersByGroupInput,
      Paged<OrganisationUser>
    >({
      method: 'GET',
      path: `/groups/${groupId}/organisationUsers`,
      queryParameters,
      metadata,
    })

    return users
  }

  /* ---------------------------------- Group --------------------------------- */

  createGroup = async (
    createGroupInput: CreateGroupInput,
    metadata?: DroidMapMetadata,
  ): Promise<Group> => {
    const group = await this.callApiRouteWithToken<CreateGroupInput, Group>({
      method: 'POST',
      path: '/groups',
      body: createGroupInput,
      metadata,
    })

    if (group === undefined) {
      throw new Error(
        'Group was not created with input: ' + JSON.stringify(createGroupInput),
      )
    }

    return group
  }

  getGroup = async (
    groupId: string,
    metadata?: DroidMapMetadata,
  ): Promise<Group> => {
    const group = await this.callApiRouteWithToken<string, Group>({
      method: 'GET',
      path: `/groups/${groupId}`,
      metadata,
    })

    return group
  }

  deleteGroup = async (
    deleteGroupInput: DeleteGroupInput,
    metadata?: DroidMapMetadata,
  ): Promise<Group> => {
    const group = await this.callApiRouteWithToken<DeleteGroupInput, Group>({
      method: 'DELETE',
      path: `/groups/${deleteGroupInput.groupId}`,
      metadata,
    })

    return group
  }

  listGroupsByOrganisation = async (
    input: ListGroupsByOrganisationInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<Group>> => {
    const { orgId, next, prev, pageSize } = input

    const queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    const groups = await this.callApiRouteWithToken<
      ListGroupsByOrganisationInput,
      Paged<Group>
    >({
      method: 'GET',
      path: `/organisations/${orgId}/groups`,
      queryParameters,
      metadata,
    })

    return groups
  }

  listGroupsByOrganisationUser = async (
    input: ListGroupsByOrganisationUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<Paged<Group>> => {
    const { orgId, userId, next, prev, pageSize } = input

    const queryParameters: QueryParameterBag =
      queryParameterBagFromPaginationControls({
        next,
        prev,
        pageSize,
      })

    const groups = await this.callApiRouteWithToken<
      ListGroupsByOrganisationInput,
      Paged<Group>
    >({
      method: 'GET',
      path: `/organisations/${orgId}/organisationUsers/${userId}/groups`,
      queryParameters,
      metadata,
    })

    return groups
  }

  /* ------------------------------ GroupUser ------------------------------ */

  createGroupUser = async (
    createGroupUserInput: CreateGroupUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<GroupUser> => {
    const groupUser = await this.callApiRouteWithToken<
      CreateGroupUserInput,
      GroupUser
    >({
      method: 'POST',
      path: `/groups/${createGroupUserInput.groupId}/groupUsers`,
      body: createGroupUserInput,
      metadata,
    })

    if (groupUser === undefined) {
      throw new Error(
        'Group user was not created with input: ' +
          JSON.stringify(createGroupUserInput),
      )
    }

    return groupUser
  }

  deleteGroupUser = async (
    deleteGroupUserInput: DeleteGroupUserInput,
    metadata?: DroidMapMetadata,
  ): Promise<GroupUser> => {
    const groupUser = await this.callApiRouteWithToken<
      DeleteGroupUserInput,
      GroupUser
    >({
      method: 'DELETE',
      path: `/groups/${deleteGroupUserInput.groupId}/groupUsers/${deleteGroupUserInput.userId}/${deleteGroupUserInput.orgId}`,
      metadata,
    })

    return groupUser
  }
}
